import {IRoadMap} from '../models'

export const roadMapItems: IRoadMap[] = [
  {
    text1: "roadmap.items.item1.text1",
    text2: "roadmap.items.item1.text2",
    number: "roadmap.items.item1.number",
    id: 1,
    color: "#5ED3D0"
  },
  {
    text1: "roadmap.items.item2.text1",
    text2: "roadmap.items.item2.text2",
    number: "roadmap.items.item2.number",
    id: 2,
    color: "#FFAA5B"

  },
  {
    text1: "roadmap.items.item3.text1",
    text2: "roadmap.items.item3.text2",
    number: "roadmap.items.item3.number",
    id: 3,
    color: "#8750F3"
  },
  {
    text1: "roadmap.items.item4.text1",
    text2: "roadmap.items.item4.text2",
    number: "roadmap.items.item4.number",
    id: 4,
    color: "#FFEE56"
  },
  {
    text1: "roadmap.items.item5.text1",
    text2: "roadmap.items.item5.text2",
    number: "roadmap.items.item5.number",
    id: 5,
    color: "#638FFF"
  }
]