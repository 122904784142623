import { Button } from "../button";
import { useState } from "react";
import plus from "../../media/images/plus.svg";
import minus from "../../media/images/minus.svg";
import plusLigt from "../../media/images/plus-light.svg";
import minusLight from "../../media/images/minus-light.svg";

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import arrow from "../../media/images/gradient-arrow.svg"
import bolt from "../../media/images/bolt.svg"

import boltMobile from "../../media/images/bolt-mobile.svg"


export function Calc() {
  let [amount, setAmount] = useState(160);
  let variant1 = 300;
  let variant2 = 1000;
  let variant3 = 3000;
  let variant4 = 25000;
  let level = 0;
  let nft_price = 0;
  let apy = 0;
  let stake = 0;
  let oneYear = 0;
  let oneYearRoi = 0;
  let twoYear = 0;
  let apyDisplay = 0;
  let twoYearRoi = 0;

  let increment = () => {
    setAmount(Number(amount) + 10);
  };
  let decrement = () => {
    if (amount > 10) {
      setAmount(amount - 10);
    }
  };
  let handleChange = (e: any) => {
    setAmount(e.target.value);
  };

  function setVariant1() {
    setAmount(function (prevAmount) {
      return (prevAmount = variant1);
    });
  }

  function setVariant2() {
    setAmount(function (prevAmount) {
      return (prevAmount = variant2);
    });
  }

  function setVariant3() {
    setAmount(function (prevAmount) {
      return (prevAmount = variant3);
    });
  }

  function setVariant4() {
    setAmount(function (prevAmount) {
      return (prevAmount = variant4);
    });
  }

  if (amount >= 50) {
    level = 2;
    nft_price = 50;
    apy = 0.4;
    apyDisplay = 40;
  }

  if (amount >= 161 && amount <= 599) {
    level = 3;
    nft_price = 110;
    apy = 0.8;
    apyDisplay = 80;
  }

  if (amount >= 600 && amount <= 2399) {
    level = 5;
    nft_price = 485;
    apy = 1.2;
    apyDisplay = 120;
  }

  if (amount >= 2400 && amount <= 23999) {
    level = 7;
    nft_price = 2139;
    apy = 2;
    apyDisplay = 200;
  }

  if (amount >= 24000) {
    level = 10;
    nft_price = 19812;
    apy = 3.0;
    apyDisplay = 300;
  }

  if (amount < 50) {
    level = 0;
    nft_price = 0;
    apy = 0;
    stake = 0;
  } 

  if (amount >= 160){
    stake = amount - nft_price;
  }

  oneYear = stake + (stake * apy);
  oneYearRoi = apyDisplay;
  twoYear = oneYear + (oneYear * apy);
  twoYearRoi = apyDisplay * 2;

  const { t } = useTranslation();
  useAos();
  return (
    <section className="pt-[120px] sm:pt-[120px] pb-[70px] md:pt-[150px] lg:pt-[265px] relative left-image left-image-digital">
      <img src={boltMobile} alt="bolt" className="absolute md:hidden top-[-7%] w-full z-[3] sm:top-[-17%] md:top-[-10%] xl:top-[-20%]" />
      <img src={bolt} alt="bolt" className="absolute hidden md:block w-full z-[3] top-[-5%] md:top-[-10%] xl:top-[-20%]" />
      <div className="container calc__container">
        <div className="md:grid md:grid-cols-2">
          <div data-aos="fade-right" className="md:mt-[50px] relative z-[4]">
            <h2 className="font-extrabold max-w-[293px] md:max-w-[500px] mx-auto md:mx-0 mb-[44px] md:mb-[0] relative z-[2] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">
              {t("gpm_token.calc.title")}
            </h2>
            <p className="mt-[33px] hidden md:block relative z-[2] lg:mt-[32px] mx-auto md:mx-0 text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] max-w-[518px] leading-[167%]">
              {t("gpm_token.calc.text")}
            </p>
            <Button
              buttonClass="button hidden mx-auto md:mx-0 transition-all md:block text-center text-[16px] md:text-[20px] mt-[38px] lg:mt-[124px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("gpm_token.calc.button")}
              buttonUrl="#"
            />
          </div>
          <div data-aos="fade-left" className="calc__wrapper md:w-fit mx-auto md:mx-0 md:ml-auto max-w-[310px] relative">
            <img src={arrow} alt="arrow" className="absolute top-[-13%] left-[3%] rotate-[45deg] md:rotate-0 md:left-[-22%] lg:left-[-27%] md:top-[2%]" />
            <div className="gradient-decor gradient-decor-low-blury absolute right-0 md:top-0 max-w-none max-h-100% w-full h-full z-[1]"></div>
            <div className="px-[12px] md:min-w-[310px] max-w-[310px] calc rounded-[30px] pt-[15px] pb-[15px] bg-[#F8FFFFB2] dark:bg-[#312D43] w-full">
              <h3 className="font-bold text-[#8750F3] max-w-[220px]">
                How much would you like to invest?
              </h3>
              <div className="flex mt-[7px] justify-between items-center gap-[10px]">
                <button
                  className="calc__item w-[50px] h-[44px] flex items-center justify-center rounded-[8px]"
                  onClick={decrement}
                >
                  <img src={minus} alt="minus" className="dark" />
                  <img src={minusLight} alt="minus" className="light" />
                </button>
                <input
                  type="number"
                  min="100"
                  onChange={handleChange}
                  step="1"
                  pattern="^\d|[1-9]\d*$"
                  value={amount}
                  className="bg-black text-center max-w-[141px] outline-0	text-[16px] font-bold text-[#8750F3] calc__item p-[10px] rounded-[8px]"
                />
                <button
                  className="calc__item w-[50px] h-[44px] flex items-center justify-center rounded-[8px]"
                  onClick={increment}
                >
                  <img src={plus} alt="plus" className="dark" />
                  <img src={plusLigt} alt="plus" className="light" />
                </button>
              </div>
              <div className="flex justify-between mt-[10px] gap-[10px]">
                <button
                  className={`calc__item ${
                    amount === variant1 ? "active" : ""
                  } calc__button rounded-[8px] transition-all px-[12px] py-[5px] font-bold text-[14px]`}
                  onClick={setVariant1}
                >
                  {variant1}
                </button>
                <button
                  className={`calc__item ${
                    amount === variant2 ? "active" : ""
                  }  calc__button rounded-[8px] transition-all px-[12px] py-[5px] font-bold text-[14px]`}
                  onClick={setVariant2}
                >
                  {variant2}
                </button>
                <button
                  className={`calc__item ${
                    amount === variant3 ? "active" : ""
                  }  calc__button rounded-[8px] transition-all px-[12px] py-[5px] font-bold text-[14px]`}
                  onClick={setVariant3}
                >
                  {variant3}
                </button>
                <button
                  className={`calc__item ${
                    amount === variant4 ? "active" : ""
                  }  calc__button rounded-[8px] transition-all px-[12px] py-[5px] font-bold text-[14px]`}
                  onClick={setVariant4}
                >
                  {variant4}
                </button>
              </div>
              <h3 className="font-bold mt-[10px] text-[#8750F3]">
                What will you get
              </h3>
              <div className="py-[9px] mt-[10px] px-[12px] calc__item rounded-[8px]">
                <div className="flex justify-between gap-[8px] font-semibold items-center">
                  <p className="text-[14px] font-bold text-[#8750F3] underline">
                    NFT Level {level}
                  </p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {nft_price}
                  </p>
                </div>
                <div className="flex mt-[6px] justify-between font-semibold gap-[8px] items-center">
                  <p className="text-[14px]">APY</p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {apyDisplay}%
                  </p>
                </div>
                <div className="flex mt-[6px] items-center font-semibold gap-[8px] justify-between">
                  <p className="text-[14px]">Token value staked </p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {Math.round(stake)}
                  </p>
                </div>
              </div>
              <div className="py-[9px] px-[12px] calc__item rounded-[8px] mt-[18px]">
                <div className="flex items-center gap-[6px] font-semibold justify-between">
                  <p className="text-[14px]">Token value after 1 y</p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {Math.round(oneYear)}
                  </p>
                </div>
                <div className="flex items-center font-semibold justify-between gap-[8px] mt-[6px]">
                  <p className="text-[14px]">1 year ROI</p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {Math.round(oneYearRoi)}%
                  </p>
                </div>
                <div className="flex items-center font-semibold justify-between gap-[8px] mt-[6px]">
                  <p className="text-[14px]">Token value after 2 y </p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {Math.round(twoYear)}
                  </p>
                </div>
                <div className="flex items-center font-semibold justify-between gap-[8px] mt-[6px]">
                  <p className="text-[14px]">2 year ROI </p>
                  <p className="px-[5px] py-[5px] bg-[#E1E7FB] dark:bg-[#221F2C] rounded-[8px] font-bold text-[#8750F3]">
                    {Math.round(twoYearRoi)}%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p data-aos="fade-right" className="mt-[55px] md:hidden relative z-[2] lg:mt-[32px] mx-auto md:mx-0 text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] max-w-[518px] leading-[167%]">
            {t("gpm_token.calc.text")}
          </p>
          <Button
            buttonClass="button block mx-auto md:mx-0 transition-all md:hidden text-center text-[16px] md:text-[20px] mt-[38px] lg:mt-[124px] max-w-[190px] md:max-w-[247px] w-full"
            buttonText={t("gpm_token.calc.button")}
            buttonUrl="#"
          />
        </div>
      </div>
    </section>
  );
}
