import { EarnPoints } from "../components/sections/earn-points";
import { MailHero } from "../components/sections/mail-hero-section";
import { MailTabs } from "../components/sections/mail-tabs";
//import {MailPhone} from "../components/sections/mail-phone-grid"
//import { FaqSection } from "../components/sections/faq-section";
import ContactUs from "../components/sections/contact-form";
import { NewsForm } from "../components/sections/newsletter";
import { Header } from "../components/header";
import ScrollToTop from "../hooks/scrollToTop";

export function GpmMail() {
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="main">
        <MailHero />
        <MailTabs />
        <EarnPoints />
        {/* 
      <MailPhone />
      <FaqSection />
      */}
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
