import { useState, useRef } from "react";
import { ntfTypesDataPresale } from "../data/nft-type-presale";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export function Presale(){
  const [slideTwo, setSlideTwo] = useState(2);
  const nodeRef = useRef(null);
  return(
    <div className="ntf-types__content overflow-hidden relative gap-[30px] lg:gap-[59px] max-w-[328px] md:max-w-[1033px] mx-auto lg:pt-[65px] px-[18px] py-[30px] md:p-[30px] lg:px-[65px] lg:pb-[43px] bg-[#312D43] rounded-[12px] md:rounded-[24px]">
    <div
      data-aos="fade-up"
      className="grid relative z-[3] grid-cols-5 md:grid-cols-10 text-[32px] md:text-[40px] xl:text-[52px] font-black gap-[15px] xl:gap-[25px]"
    >
      <button
        disabled
        className={`bg-[#221F2C] button-disabled rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
      >
        1
      </button>
      <button
        className={`bg-[#221F2C] ${
          slideTwo === 2 ? "active" : ""
        } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
        onClick={() => setSlideTwo(2)}
      >
        2
      </button>
      <button
        className={`bg-[#221F2C] ${
          slideTwo === 3 ? "active" : ""
        } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
        onClick={() => setSlideTwo(3)}
      >
        3
      </button>
      <button
        disabled
        className={`bg-[#221F2C] button-disabled rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
      >
        4
      </button>
      <button
        className={`bg-[#221F2C] ${
          slideTwo === 5 ? "active" : ""
        } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
        onClick={() => setSlideTwo(5)}
      >
        5
      </button>
      <button
        disabled
        className={`bg-[#221F2C] button-disabled rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
      >
        6
      </button>
      <button
        className={`bg-[#221F2C] ${
          slideTwo === 7 ? "active" : ""
        } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
        onClick={() => setSlideTwo(7)}
      >
        7
      </button>
      <button
        disabled
        className={`bg-[#221F2C] button-disabled rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
      >
        8
      </button>
      <button
        disabled
        className={`bg-[#221F2C] button-disabled rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
      >
        9
      </button>
      <button
        className={`bg-[#221F2C] ${
          slideTwo === 10 ? "active" : ""
        } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
        onClick={() => setSlideTwo(10)}
      >
        10
      </button>
    </div>
    <div className="gradient-decor md:opacity-50 top-[10%] types-decor w-full h-full absolute max-w-[200px] max-h-[200px] md:max-w-[450px] md:max-h-[450px] left-[10%]"></div>
    <div className="gradient-decor md:opacity-50 bottom-0 md:bottom-[-50%] dark:hidden types-decor w-full h-full absolute max-w-[200px] max-h-[200px] md:max-w-[450px] md:max-h-[450px] right-[10%]"></div>
    <TransitionGroup className="nft-types">
      {ntfTypesDataPresale.map(
        (item) =>
          slideTwo === item.id && (
            <CSSTransition
              key={item.id}
              ref={nodeRef}
              timeout={500}
              classNames="fade"
            >
              <div
                data-aos="fade-up"
                className="grid relative z-[3] max-w-[400px] mx-auto lg:mx-0 lg:max-w-none lg:grid-cols-2 gap-[25px] md:gap-[30px] lg:gap-[59px]"
              >
                <img
                  src={item.image}
                  alt="slide"
                  className="object-cover rounded-[12px] w-full"
                />
                <div className="bg-[#221F2C] max-w-[400px] w-full lg:max-w-none lg:h-[274px] flex flex-col justify-center mt-auto types__slide-item rounded-[12px] p-[16px] md:p-[40px]">
                  <h3 className="text-[24px] text-center md:text-left md:ext-[30px] lg:text-[40px] types__slide-title font-extrabold">
                    {item.text}
                  </h3>
                  <div className="md:flex text-center md:text-left mt-[15px] md:mt-[25px] items-center justify-between gap-[5px]">
                    <p className="text-[20px] lg:text-[28px] font-extrabold">
                      {item.price}
                    </p>
                    <s className="text-[14px] md:mt-0 mt-5px md:text-[18px] lg:text-[24px] font-bold max-w-[140px] text-right">{item.discount}</s>
                  </div>
                  <div className="md:flex text-center md:text-left mt-[7px] md:mt-[20px] items-center justify-between gap-[5px]">
                    <p className="text-[20px] lg:text-[28px] font-extrabold">
                      {item.percent}
                    </p>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )
      )}
    </TransitionGroup>
  </div>
  )
}