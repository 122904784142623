import { useState, useRef } from "react";
import { ntfTypesDataRegular } from "../data/nft-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export function Regular() {
  const [slide, setSlide] = useState(1);
  const nodeRef = useRef(null);
  return (
    <div className="ntf-types__content overflow-hidden relative max-w-[328px] md:max-w-[1033px] mx-auto lg:pt-[65px] px-[18px] py-[30px] md:p-[30px] lg:px-[65px] lg:pb-[43px] bg-[#312D43] rounded-[12px] md:rounded-[24px]">
      <div
        data-aos="fade-up"
        className="grid relative z-[3] grid-cols-5 md:grid-cols-10 text-[32px] md:text-[40px] xl:text-[52px] font-black gap-[15px] xl:gap-[25px]"
      >
        <button
          className={`bg-[#221F2C] ${
            slide === 1 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(1)}
        >
          1
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 2 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(2)}
        >
          2
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 3 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(3)}
        >
          3
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 4 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(4)}
        >
          4
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 5 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(5)}
        >
          5
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 6 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(6)}
        >
          6
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 7 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(7)}
        >
          7
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 8 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(8)}
        >
          8
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 9 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(9)}
        >
          9
        </button>
        <button
          className={`bg-[#221F2C] ${
            slide === 10 ? "active" : ""
          } rounded-[8px] h-[68px] flex items-center pt-[4px] justify-center types__slide-button`}
          onClick={() => setSlide(10)}
        >
          10
        </button>
      </div>
      <div className="gradient-decor opacity-50 top-[10%] types-decor w-full h-full absolute max-w-[450px] max-h-[450px] left-[10%]"></div>
      <div className="gradient-decor opacity-50 bottom-[-50%] dark:hidden types-decor w-full h-full absolute max-w-[450px] max-h-[450px] right-[10%]"></div>
      <TransitionGroup className="nft-types min-h-[269px]">
        {ntfTypesDataRegular.map(
          (item) =>
            slide === item.id && (
              <CSSTransition
                key={item.id}
                ref={nodeRef}
                timeout={500}
                classNames="fade"
              >
                <div
                  data-aos="fade-up"
                  className={`grid relative z-[3] ${item.cssClass} lg:grid-cols-2 gap-[25px] items-end md:gap-[30px] lg:gap-[59px]`}
                >
                  <img
                    src={item.image}
                    alt="slide"
                    className="object-cover max-w-[400px] w-full lg:max-w-none mx-auto lg:mx-0 rounded-[12px]"
                  />
                  <div className={`bg-[#221F2C] max-h-[275px] h-full  max-w-[400px] w-full lg:max-w-none mx-auto lg:mx-0 h-full flex flex-col justify-center types__slide-item rounded-[12px] p-[16px] md:p-[40px]`}>
                    <h3 className="text-[24px] text-center md:text-left md:text-[30px] lg:text-[40px] types__slide-title font-extrabold">
                      {item.text}
                    </h3>
                    <p className="mt-[25px] text-center md:text-left text-[20px] lg:text-[28px] font-extrabold">
                      {item.price}
                    </p>
                    <p className="mt-[20px] text-center md:text-left text-[20px] lg:text-[28px] font-extrabold">
                      {item.percent}
                    </p>
                  </div>
                </div>
              </CSSTransition>
            )
        )}
      </TransitionGroup>
    </div>
  );
}
