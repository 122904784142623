import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

import { earnPointsData } from "../../data/earn-points";

export function EarnPoints() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="relative z-[3] md:pt-[88px]">
      <div className="lg:hidden gradient-decor absolute z-[1] right-[-10%] top-0 max-w-[215px] max-h-[215px] w-full h-full"></div>
      <div className="container">
        <h2
          data-aos="fade-right"
          className="font-extrabold hidden lg:block relative z-[2] text-center max-w-[745px] leading-[125%] mx-auto text-[24px] md:text-[30px] lg:text-[40px]"
        >
          {t("earn-points.title")}
        </h2>
        <div data-aos="fade-left" className="grid lg:mt-[36px] max-w-[300px] md:max-w-[400px] mx-auto lg:mx-auto lg:max-w-none rounded-[16px] lg:rounded-[20px] gap-[20px] md:gap-[35px] relative z-[2] lg:grid-cols-4 pt-[32px] pb-[39px] px-[47px] md:p-[65px] earn-points__content">
          <h2
            data-aos="fade-right"
            className="font-extrabold text-[#2F2F35] lg:hidden relative z-[2] text-center max-w-[745px] leading-[125%] mx-auto text-[24px] mb-[9px] md:text-[30px] lg:text-[40px]"
          >
            {t("earn-points.title")}
          </h2>
          {earnPointsData.map((item) => (
            <div key={item.id} className="pt-[23px] md:pt-[26px] mb-[15px] md:mb-[20px] px-[14px] flex flex-col transition-all pb-[28px] md:pb-[44px] rounded-[20px] bg-[#312D43] earn-points__item">
              <img src={item.image} alt="icon" className={`mx-auto image__${item.id}`}  />
              <p className="max-w-[171px] mt-auto font-extrabold text-center mx-auto">{t(`${item.text}`)}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:hidden gradient-decor absolute z-[1] left-[-10%] top-[40%] max-w-[215px] max-h-[215px] w-full h-full"></div>
      <div className="lg:hidden gradient-decor absolute z-[1] right-[-10%] bottom-[-10%] max-w-[215px] max-h-[215px] w-full h-full"></div>
    </section>
  );
}
