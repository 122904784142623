import { useTranslation  } from "react-i18next";
import "../utils/i18next"
import { useState, useEffect } from 'react';

export function useLangSwitcher() {
  let [langState, setLangState] = useState("en");
  const { i18n } = useTranslation();
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLangState((langState = lang));
  };

  useEffect(() => {
    const data = window.localStorage.getItem("lang_mode_state");
    if (data !== null) setLangState(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("lang_mode_state", JSON.stringify(langState));
  }, [langState]);
  
  return {changeLanguage, langState, setLangState};
}
