import { ButtonLink } from "../buttonLink";
import "@dotlottie/player-component";

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function DigitalImageLeft() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="left-image md:overflow-hidden left-image-digital relative pt-[70px] pb-[70px] md:py-[126px]">
      <div className="container">
      <h2 data-aos="fade-right" className="font-extrabold text-center max-w-[745px] mx-auto text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{t("digital_team.image-left.title")}</h2>
        <div className="grid relative z-[2] items-center gap-[31px] md:gap-[59px] mt-[25px] md:mt-[59px] relative z-[2] md:grid-cols-2">
          <div className="relative">
            <dotlottie-player
              src="lottie/dashboard.lottie"
              autoplay
              loop
              style={{ height: "100%", width: "100%", position: "relative", zIndex: "2" }}
            />
          </div>
          <div data-aos="fade-left" className="relative z-[2]">
          <p className="text-center md:text-left mx-auto md:mx-0 max-w-[499px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">{t("digital_team.image-left.text")}</p>
            <ButtonLink
              buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[40px] lg:mt-[70px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("digital_team.image-left.button")}
              buttonUrl="https://calendly.com/davidgpm/30min"
            />
          </div>
        </div>
      </div>
      <div className="md:hidden gradient-decor absolute z-[1] right-[-10%] bottom-[-7%] max-w-[215px] max-h-[215px] w-full h-full"></div>
    </section>
  );
}
