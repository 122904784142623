import { ntfTypesPresale } from '../models'

export const ntfTypesDataPresale: ntfTypesPresale[] = [
  {
    image: "images/pre(2).gif",
    text: "NFT Level 2",
    price: "50 $BUSD",
    newPercent: "+140% per year",
    discount: "110 $BUSD",
    percent: "+40% per year",
    id: 2,
  },
  {
    image: "images/pre3(2).gif",
    text: "NFT Level 3",
    price: "110 $BUSD",
    newPercent: "+160% per year",
    discount: "231 $BUSD",
    percent: "+80% per year",
    id: 3,
  },
  {
    image: "images/pre4(2).gif",
    text: "NFT Level 5",
    price: "485 $BUSD",
    newPercent: "+200% per year",
    discount: "1 019 $BUSD",
    percent: "+120% per year",
    id: 5,
  },
  {
    image: "images/pre5(2).gif",
    text: "NFT Level 7",
    price: "2 139 $BUSD",
    newPercent: "+240% per year",
    discount: "4 493 $BUSD",
    percent: "+200% per year",
    id: 7,
  },
  {
    image: "images/pre6(2).gif",
    text: "NFT Level 10",
    price: "19 812 $BUSD",
    newPercent: "+300% per year",
    discount: "41 605 $BUSD",
    percent: "+300% per year",
    id: 10,
  }
]