import {spoilers} from '../models'

export const linkFaq: spoilers[] = [
  {
    title: "link-faq.item1.title",
    text: "token-faq.item1.text",
    id: 1,
    spoilerState: true
  },
  {
    title: "link-faq.item2.title",
    text: "link-faq.item2.text",
    id: 2,
    spoilerState: false
  },
  {
    title: "link-faq.item3.title",
    text: "link-faq.item3.text",
    id: 3,
    spoilerState: false
  },
  {
    title: "link-faq.item4.title",
    text: "link-faq.item4.text",
    id: 4,
    spoilerState: false
  }
]