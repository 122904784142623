import { benefits } from '../models'

export const benefitsData: benefits[] = [
  {
    text1: "benefits.item1.part1",
    text2: "benefits.item1.part2",
    text3: "benefits.item1.part3",
    image: "images/benefits1.png",
    id: 1
  },
  {
    text1: "benefits.item2.part1",
    text2: "benefits.item2.part2",
    text3: "benefits.item2.part3",
    image: "images/benefits2.png",
    id: 2
  },
  {
    text1: "benefits.item3.part1",
    text2: "benefits.item3.part2",
    text3: "benefits.item3.part3",
    image: "images/benefits3.png",
    id: 3
  }
]