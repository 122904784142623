import {locationSlide} from '../models'

export const locationSlideData: locationSlide[] = [
  {
    image: "images/location1.png",
    title: "location-slider.slide1.title",
    time: "location-slider.slide1.time",
    discountPrice: "location-slider.slide1.discountPrice",
    price: "location-slider.slide1.price",
    date: "location-slider.slide1.date",
    percent: "location-slider.slide1.percent",
    country: "location-slider.slide1.country",
    id: 1
  },
  {
    image: "images/location2.png",
    title: "location-slider.slide2.title",
    time: "location-slider.slide2.time",
    discountPrice: "location-slider.slide2.discountPrice",
    price: "location-slider.slide2.price",
    date: "location-slider.slide2.date",
    percent: "location-slider.slide2.percent",
    country: "location-slider.slide2.country",
    id: 2
  },
  {
    image: "images/location3.png",
    title: "location-slider.slide3.title",
    time: "location-slider.slide3.time",
    discountPrice: "location-slider.slide3.discountPrice",
    price: "location-slider.slide3.price",
    date: "location-slider.slide3.date",
    percent: "location-slider.slide3.percent",
    country: "location-slider.slide3.country",
    id: 3
  },
  {
    image: "images/location4.png",
    title: "location-slider.slide4.title",
    time: "location-slider.slide4.time",
    discountPrice: "location-slider.slide4.discountPrice",
    price: "location-slider.slide4.price",
    date: "location-slider.slide4.date",
    country: "location-slider.slide4.country",
    percent: "location-slider.slide4.percent",
    id: 4
  }
]