import { locationSlideData } from "../../data/location-slider";
import { locationSlideTumbData } from "../../data/location-slider-tumb";
import clock from "../../media/images/clock.svg";
import date from "../../media/images/date.svg";
import arrow from "../../media/images/gradient-arrow.svg"

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Pagination, Thumbs, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { useState } from "react";
import { Button } from "../button";
import { locationSwiperProps } from "../../models"

export function LocationSwiper(props: locationSwiperProps) {
  const [activeTumb, setActiveTumb] = useState<any>(null);

  const gallerySwiperParams: SwiperProps = {
    spaceBetween: 10,
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };
  const thumbnailSwiperParams: SwiperProps = {
    spaceBetween: 40,
    autoHeight: true,
    direction: "vertical",
    slidesPerView: 4,
    slideToClickedSlide: true,
  };

  const { t } = useTranslation();
  useAos();
  return (
    <section className="location-swiper relative pb-[70px] pt-[70px] md:pt-[243px] md:pb-[137px]">
      <div className="gradient-decor absolute w-full h-full max-w-[412px] opacity-60 max-h-[412px] top-[19%] left-[-15%]"></div>
      <div className="container location-swiper__container">
        <h2
          data-aos="fade-right"
          className="block px-[28px] md:p-0 lg:hidden text-center mx-auto max-w-[288px] mx-auto md:max-w-[562px] font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]"
        >
          {t("location-slider.title")}
        </h2>
        <div className="grid grid-cols-1 mt-[34px] lg:mt-0 lg:grid-cols-2 md:gap-[30px] lg:gap-0 items-center relative z-[2]">
          <div data-aos="fade-left" className="grid-row-2">
            <h2 className="hidden lg:block text-center md:text-left max-w-[562px] font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]">
              {props.title}
            </h2>
            <p className="px-[28px] md:p-0 mt-[36px] lg:mt-[10px] mx-auto lg:mx-0 text-center lg:text-left lg:mt-[47px] max-w-[499px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">
              {props.text}
            </p>
            <Button
              buttonClass="button mx-auto lg:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[36px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("location-slider.button")}
              buttonUrl="/GPM-Link"
            />
          </div>
          <div
            data-aos="fade-right"
            className="flex justify-center relative lg:justify-end items-center h-full"
          >
            <img src={arrow} alt="arrow" className="absolute location-arrow top-[78%] hidden lg:block left-[3%] xl:left-[10%]" />
            <Swiper
              modules={[Thumbs]}
              onSwiper={setActiveTumb}
              className="thumbs"
              {...thumbnailSwiperParams}
            >
              {locationSlideTumbData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="relative thumbs__content">
                    <img
                      src={item.image}
                      alt="thumb_slide"
                      className="rounded-[6px]"
                    />
                    <h4 className="absolute text-accentWhite top-[50%] text-[12px] sm:text-[16px] text-center left-[50%] translate-y-[-50%] translate-x-[-50%]">
                      {t(`${item.title}`)}
                    </h4>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              modules={[Thumbs, EffectFade, Pagination]}
              effect="fade"
              className="location-slider"
              thumbs={{ swiper: activeTumb }}
              {...gallerySwiperParams}
            >
              {locationSlideData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div>
                    <div className="relative">
                      <div className="relative">
                        <img
                          src={item.image}
                          className="sm:max-h-none w-full h-full object-cover rounded-[12px]"
                          alt="slide_location"
                        />
                        <p className="sm:w-[60px] w-[42px] h-[42px] absolute sm:top-[-6%] top-[-3%] right-[-3%] sm:right-[-6%] font-bold text-[14px] sm:text-[20px] sm:h-[60px] discount-percent flex items-center justify-center rounded-[50%] bg-[#FF4A6B]">
                          {t(`${item.percent}`)}
                        </p>
                      </div>
                      <h4 className="location-slider__country text-accentWhite  text-center font-bold py-[6px] sm:py-[12px] absolute w-full bottom-0 left-[50%] translate-x-[-50%]">
                        {t(`${item.country}`)}
                      </h4>
                    </div>
                    <h3 className="text-[14px] sm:text-[20px] mt-[10px] text-center text-accentWhite font-bold">
                      {t(`${item.title}`)}
                    </h3>
                    <div className="flex mt-[10px] sm:mt-[26px] items-center justify-between">
                      <p className="flex text-accentWhite items-center sm:text-[14px] text-[12px]">
                        <img src={clock} alt="clock" className="mr-[5px]" />
                        {t(`${item.time}`)}
                      </p>
                      <p className="text-[14px] font-bold text-accentWhite sm:text-[22px]">
                        {t(`${item.price}`)}
                      </p>
                    </div>
                    <div className="flex mt-[10px] items-center justify-between">
                      <p className="flex text-accentWhite items-center text-[9px] sm:text-[12px]">
                        <img src={date} alt="date" className="mr-[5px]" />
                        {t(`${item.date}`)}
                      </p>
                      <p className="text-[#B1B7E7] text-accentWhite relative before:absolute before:top-[50%] before:translate-y-[-50%] before:content-[''] before:w-full before:h-[2px] before:bg-[#B1B7E7] text-[12px] sm:text-[16px]">
                        {t(`${item.discountPrice}`)}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
