import appStore from "../../media/images/app-store.svg";
import googleStore from "../../media/images/google-store.svg";
import { footerLinks1 } from "../../data/footer-links1";
import { footerLinks2 } from "../../data/footer-links2";
import { socialLinks } from "../../data/social-links";
import enFlag from "../../media/images/en-flag.svg";
import frFlag from "../../media/images/france-flag.svg";
import espFlag from "../../media/images/spain-flag.svg";
import logo from "../../media/images/desktop_logo.webp";

import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FooterLang } from "./footer-lang-switcher";

export function FooterMobile() {
  const { t } = useTranslation();
  const [showLangs, setShowLangs] = useState(false);
  let langRef = useRef<any>();

  useEffect(() => {
    const node = langRef.current;
    let handler = (e: any) => {
      if (!node?.contains(e.target)) {
        setShowLangs(false);
      }
    };

    document.addEventListener("mousedown", handler);
  });
  useAos();
  return (
    <footer className="block md:hidden footer-mobile pt-[63px] pb-[40px]">
      <div className="container">
        <details open data-aos="fade-right" className="faq__spoiler mb-[23px]">
          <summary className="flex items-center justify-between font-extrabold text-[16px]">
            {t("footer.language_title")}
            <div className="relative">
              <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
              <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
            </div>
          </summary>
          <div className="relative" onClick={() => setShowLangs(!showLangs)} ref={langRef}>
            {i18n.languages[0] === "en" && (
              <div className="flex items-center mt-[14px]">
                <img
                  src={enFlag}
                  alt="en"
                  className="max-w-[26px] max-h-[19px] mr-[9px]"
                />
                <p className="text-[12px]">English</p>
              </div>
            )}
            {i18n.languages[0] === "fr" && (
              <div className="flex items-center mt-[14px]">
                <img
                  src={frFlag}
                  alt="fr"
                  className="max-w-[26px] max-h-[19px] mr-[9px]"
                />
                <p className="text-[12px]">Français</p>
              </div>
            )}
            {i18n.languages[0] === "esp" && (
              <div className="flex items-center mt-[14px]">
                <img
                  src={espFlag}
                  alt="esp"
                  className="max-w-[26px] max-h-[19px] mr-[9px]"
                />
                <p className="text-[12px]">Español</p>
              </div>
            )}
           {showLangs && <FooterLang/>}
          </div>
        </details>
        <details data-aos="fade-left" className="faq__spoiler mb-[23px]">
          <summary className="flex items-center justify-between font-extrabold text-[16px]">
            {t("footer.link_list_title")}
            <div className="relative">
              <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
              <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
            </div>
          </summary>
          <ul className="mt-[9px] flex flex-col gap-[10px]">
            {footerLinks1.map((link) => (
              <Link
                to={link.url}
                className="bloc text-[12px] font-normal transition-all hover:text-purple"
                key={link.id}
              >
                {t(`${link.text}`)}
              </Link>
            ))}
          </ul>
        </details>
        <details data-aos="fade-right" className="faq__spoiler mb-[23px]">
          <summary className="flex items-center justify-between font-extrabold text-[16px]">
            {t("footer.link_list_title2")}
            <div className="relative">
              <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
              <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
            </div>
          </summary>
          <ul className="mt-[9px] flex flex-col gap-[10px]">
            {footerLinks2.map((link) => (
              <a
                href={link.url}
                className="block text-[12px] font-normal transition-all hover:text-purple"
                key={link.id}
              >
                {t(`${link.text}`)}
              </a>
            ))}
          </ul>
        </details>
        <details data-aos="fade-left" className="faq__spoiler mb-[23px]">
          <summary className="flex items-center justify-between font-extrabold text-[16px]">
            {t("footer.link_list_title3")}
            <div className="relative">
              <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
              <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
            </div>
          </summary>
          <div className="flex social-links items-center mt-[20px] gap-[10px]">
            <a href="/" className="max-w-[35px]">
              <img src={logo} alt="gpm_logo" />
            </a>
            {socialLinks.map((link) => (
              <a className="max-w-[35px]" href={link.url} key={link.id}>
                {link.image}
              </a>
            ))}
          </div>
          <a
            href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
            target="_blank"
            className="max-w-[150px] relative z-[1] hidden dark:block w-full mt-[25px] block"
            rel="noopener noreferrer"
            title="View our Ecologi profile"
          >
            <img
              alt="We offset our carbon footprint via Ecologi"
              src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?white=true&landscape=true"
              className="w-full"
            />
          </a>
          <a
            href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
            target="_blank"
            className="max-w-[150px] relative z-[1] dark:hidden w-full mt-[25px] block"
            rel="noopener noreferrer"
            title="View our Ecologi profile"
          >
            <img
              alt="We offset our carbon footprint via Ecologi"
              src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?black=true&landscape=true"
              className="w-full"
            />
          </a>
        </details>
        <details data-aos="fade-right" className="faq__spoiler">
          <summary className="flex items-center justify-between font-extrabold text-[16px]">
            {t("footer.app")}
            <div className="relative">
              <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
              <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
            </div>
          </summary>
          <a href="/" className="block mt-[9px]">
            <img src={appStore} alt="logo" />
          </a>
          <a href="/">
            <img src={googleStore} className="ml-[-5px]" alt="logo" />
          </a>
        </details>
        <p className="mt-[62px] text-center text-[10px]">
          {t("footer.copyright")}
        </p>
      </div>
    </footer>
  );
}
