import {ISocialLinks} from '../models'

export const socialLinks: ISocialLinks[] = [
  {
    url: "#",
    image: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM19.0461 8C20.6918 8.26026 22.2578 8.74455 23.7585 9.4095L23.759 9.40995C26.3571 13.1255 27.3193 17.1363 26.9086 21.3017H26.9081C25.0018 22.6482 23.1538 23.4656 21.3368 24C20.8877 23.4173 20.4885 22.7966 20.1449 22.1438C20.8015 21.9077 21.4308 21.6165 22.0263 21.2776C21.8688 21.1671 21.7156 21.0527 21.5651 20.9337C17.9886 22.5346 14.055 22.5346 10.4345 20.9337C10.284 21.0527 10.1307 21.1671 9.97331 21.2776C10.5669 21.6157 11.1948 21.9059 11.8505 22.142C11.5069 22.7953 11.1078 23.4155 10.6586 23.9987C8.84303 23.4643 6.99642 22.6468 5.09147 21.3008H5.09101C4.68029 17.1363 5.64249 13.1255 8.24061 9.4095C9.65428 8.74947 11.2448 8.30319 12.9534 8C13.1498 8.33359 13.3276 8.67881 13.4841 9.03522C15.1459 8.79419 16.8235 8.79419 18.5155 9.03522C18.6725 8.67881 18.8498 8.33359 19.0461 8ZM10.4178 16.7307C10.4178 17.9001 11.3101 18.8588 12.3969 18.8588C13.5026 18.8588 14.3759 17.9005 14.3759 16.7307C14.3949 15.5609 13.5031 14.6017 12.3969 14.6017C11.2907 14.6017 10.4178 15.5613 10.4178 16.7307ZM17.6292 16.7307C17.6292 17.9001 18.5025 18.8588 19.6083 18.8588C20.695 18.8588 21.5873 17.9005 21.5873 16.7307C21.5873 15.5609 20.714 14.6017 19.6083 14.6017C18.5025 14.6017 17.6102 15.5609 17.6292 16.7307Z" fill="white"/></svg>,
    id: 2
  },
  {
    url: "#",
    image: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM24.2644 10.8715C25.1135 10.7631 25.9184 10.534 26.6668 10.1907C26.1085 11.0555 25.4014 11.8124 24.5812 12.4123C24.5897 12.5951 24.5953 12.7802 24.5953 12.9659C24.5953 18.6359 20.3914 25.1717 12.7069 25.1717C10.3487 25.1717 8.15169 24.4605 6.30142 23.2472C6.62837 23.2882 6.95928 23.3081 7.29754 23.3081C9.25585 23.3081 11.0569 22.6203 12.4869 21.472C10.6592 21.4351 9.1167 20.1925 8.58498 18.4923C8.83952 18.5398 9.10255 18.5714 9.37237 18.5714C9.75249 18.5714 10.1213 18.5164 10.4703 18.4174C8.56065 18.0231 7.11993 16.2907 7.11993 14.2103V14.1599C7.68445 14.4763 8.3293 14.6737 9.01431 14.6954C7.89205 13.9232 7.15613 12.6127 7.15613 11.124C7.15613 10.3407 7.35977 9.59842 7.72179 8.96453C9.78191 11.5587 12.8613 13.2682 16.3333 13.4487C16.2621 13.1317 16.2242 12.8054 16.2242 12.4703C16.2242 10.1023 18.0948 8.18182 20.4027 8.18182C21.6041 8.18182 22.6885 8.69972 23.4487 9.53457C24.4007 9.34475 25.2956 8.98444 26.1034 8.49408C25.7917 9.49414 25.1276 10.3407 24.2644 10.8715Z" fill="white"/></svg>,
    id: 3
  },
  {
    url: "#",
    image: <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5ZM25.4135 7.4375L22.0578 24.5712C22.0578 24.5712 21.9135 25.3542 20.9424 25.3542C20.4264 25.3542 20.1604 25.1087 20.1604 25.1087L15.6039 21.3278L12.5353 24.3499C12.5353 24.3499 12.483 24.3902 12.4003 24.4215C12.3836 24.4278 12.3658 24.4337 12.3469 24.4389C12.3094 24.4491 12.2677 24.4563 12.2233 24.4574C12.1615 24.4592 12.0953 24.4494 12.0272 24.419L12.0271 24.4198C12.0271 24.4198 11.796 24.3983 11.5075 23.4837C11.22 22.5699 9.33333 17.2917 9.33333 17.2917L9.34066 17.2873L9.33513 17.2845L4.77085 16.0706C4.77085 16.0706 3.95833 15.8359 3.95833 15.1641C3.95833 14.6042 4.79415 14.3372 4.79415 14.3372L23.8897 6.75129C23.8888 6.7504 24.4729 6.54077 24.8984 6.54167C25.16 6.54167 25.4583 6.65365 25.4583 6.98958C25.4583 7.21354 25.4135 7.4375 25.4135 7.4375Z" fill="white"/><path d="M21.7824 10.3005C21.631 10.1034 21.3515 10.0676 21.1544 10.2172L9.33301 17.2916C9.33301 17.2916 11.2196 22.5698 11.5072 23.4836C11.7957 24.3982 12.0268 24.4197 12.0268 24.4197L12.8904 19.0761L21.6982 10.9276C21.8953 10.778 21.932 10.4976 21.7824 10.3005Z" fill="white" fillOpacity="0.1"/></svg>,
    id: 4
  },
  {
    url: "#",
    image: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM7.2 12.3348C7.2 9.5041 9.50197 7.2 12.3319 7.2H19.6652C22.4959 7.2 24.8 9.50197 24.8 12.3319V19.6652C24.8 22.4959 22.498 24.8 19.6681 24.8H12.3348C9.5041 24.8 7.2 22.498 7.2 19.6681V12.3348ZM21.8667 10.8667C21.8667 10.4619 21.5381 10.1333 21.1333 10.1333C20.7285 10.1333 20.4 10.4619 20.4 10.8667C20.4 11.2715 20.7285 11.6 21.1333 11.6C21.5381 11.6 21.8667 11.2715 21.8667 10.8667ZM20.4 16C20.4 13.5734 18.4266 11.6 16 11.6C13.5734 11.6 11.6 13.5734 11.6 16C11.6 18.4266 13.5734 20.4 16 20.4C18.4266 20.4 20.4 18.4266 20.4 16ZM13.9258 13.9258C14.4759 13.3757 15.222 13.0667 16 13.0667C16.778 13.0667 17.5241 13.3757 18.0742 13.9258C18.6243 14.4759 18.9333 15.222 18.9333 16C18.9333 16.778 18.6243 17.5241 18.0742 18.0742C17.5241 18.6243 16.778 18.9333 16 18.9333C15.222 18.9333 14.4759 18.6243 13.9258 18.0742C13.3757 17.5241 13.0667 16.778 13.0667 16C13.0667 15.222 13.3757 14.4759 13.9258 13.9258Z" fill="white"/></svg>,
    id: 5
  }
]