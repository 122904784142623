import ContactUs from "../components/sections/contact-form";
// import { LocationSwiper } from "../components/sections/location-swiper";
//import { FaqSection } from "../components/sections/faq-section";
//import { HeroSection } from "../components/sections/hero-section";
import { ImageLeft } from "../components/sections/image-left";
import { ImageRight } from "../components/sections/image-right";
import { ImageRightNoDecor } from "../components/sections/image-right-no-decor";
import { NewsForm } from "../components/sections/newsletter";
//import { RoadMap } from "../components/sections/road-map";
import { ThreeBackground } from "../components/sections/tree-background-section";
// import { DiscountSwiper } from "../components/sections/discont-swiper";
import { Header } from "../components/header";
import ScrollToTop from "../hooks/scrollToTop";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
//import { BuildProfile } from "../components/sections/profile-build-section";

export function HomePage() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="main home-page">
        <ImageRight />
        {/* 
      <HeroSection
        button={`${t("hero-section.button")}`}
        text={`${t("hero-section.subtitle")}`}
        title1={`${t("hero-section.title1")}`}
        title2={`${t("hero-section.title3")}`}
      />
      */}
        <ThreeBackground text={t("tree-background.home-text")} />
        {/* 
      <BuildProfile />
      */}
        {/* 
      <DiscountSwiper />
      <LocationSwiper title={t("location-slider.title")} text={t("location-slider.text")} />
      */}
        <ImageLeft />
        <ImageRightNoDecor />
        {/* <RoadMap /> */}
        {/*  
      <FaqSection />
      */}
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
