import { MobileMenu } from "./mobile-menu";

import { useState } from "react";

export function BurgerMenu() {
  const [burgerMenu, setBurgerMenu] = useState(false);

  burgerMenu
    ? document.body.classList.add("_lock")
    : document.body.classList.remove("_lock");
  burgerMenu
    ? document.documentElement.classList.add("overflow-hidden")
    : document.documentElement.classList.remove("overflow-hidden");

  function showBurger() {
    setBurgerMenu(!burgerMenu);
  }

  return (
    <div className="burger-menu">
      <nav className="header__menu-mobile md:hidden block">
        <button
          onClick={showBurger}
          className={`header__menu-burger w-[53px] flex flex-col gap-[5px] relative z-[21] ${
            burgerMenu ? "_active" : ""
          }`}
          type="button"
        >
          <span className="w-[22px] transition-all dark:bg-accentWhite bg-accentDark h-[3px]"></span>
          <span className="w-[22px] transition-all dark:bg-accentWhite bg-accentDark h-[3px]"></span>
          <span className="w-[22px] transition-all dark:bg-accentWhite bg-accentDark h-[3px]"></span>
        </button>

        <div
          className={`header-menu__wrapper max-w-[768px] absolute h-full z-[-20] w-full top-0 left-[50%] ${
            burgerMenu ? "_active" : ""
          }`}
        >
          <div className="absolute transition-all z-[1] burger-decor w-full h-full top-0 dark:top-[300%] max-w-[200px] h-[200px] left-[-15%]"></div>
          <ul className="header__menu-nav bg-accentDark/50 z-[2] relative min-h-[97vh] gap-[20px] transition-all pt-[84px] px-[28px]">
            <MobileMenu click={() => setBurgerMenu(false)} />
          </ul>
          <div className="absolute transition-all z-[1] dark:top-0 burger-decor__green w-full h-full max-w-[200px] h-[200px] right-[-15%]"></div>
        </div>
      </nav>
    </div>
  );
}
