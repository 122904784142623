import { Button } from "../button";
import image from "../../media/images/button-images.gif";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export function Referals() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="mt-[70px] relative md:mt-[87px]">
      <div className="container">
        <div className="grid relative z-[3] md:grid-cols-2 lg:grid-cols-[1fr_.7fr] md:gap-[30px] items-center">
          <div data-aos="fade-left">
            <h2 className="md:max-w-[499px] relative z-[2] font-extrabold mt-[30px] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">
              {t("gpm_token.refs.title")}
            </h2>
            <p className="text-center mt-[20px] md:mt-[28px] mb-auto md:text-left mx-auto md:mx-0 max-w-[471px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">
              {t("gpm_token.refs.text")}
            </p>
            <Button
              buttonClass="button hidden md:block mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[30px] lg:mt-[98px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("gpm_token.refs.button")}
              buttonUrl="#"
            />
          </div>
          <div data-aos="fade-right">
            <div>
              <img
                src={image}
                alt="tree-man"
                className="md:max-w-none w-full mx-auto md:mx-0 max-w-[400px]"
              />
              <dotlottie-player
                src="lottie/token-page-buttons.json"
                autoplay
                class="md:max-w-none w-full mx-auto md:mx-0 max-w-[400px]"
                loop
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  zIndex: "2",
                }}
              />
            </div>
            <Button
              buttonClass="button md:hidden mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[59px] lg:mt-[98px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("gpm_token.refs.button")}
              buttonUrl="#"
            />
          </div>
        </div>
      </div>
      <div className="gradient-decor z-[1] md:opacity-70 absolute max-w-[200px] max-h-[200px] lg:max-w-[412px] lg:max-h-[412px] w-full h-full bottom-[30%] right-[12%] lg:bottom-[-15%] lg:left-[-10%]"></div>
    </section>
  );
}
