import { Button } from "../button";
import { gpmTravelHeroData } from "../../data/gpm-travel-hero";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";

export function GpmTravelHero() {
  const { t } = useTranslation();

  const gallerySwiperParams: SwiperProps = {
    slidesPerView: 1.9,
    centeredSlides: true,
    loop: true,
    speed: 500,
    grabCursor: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 23,
        centeredSlides: false,
      },
    },
  };
  return (
    <section className="mt-[130px] relative md:mt-[203px] mb-[80px] md:mb-[116px]">
      <div className="gradient-decor hidden md:block max-h-[212px] max-w-[212px] absolute md:max-w-[413px] top-[20%] left-[40%] md:top-[-40%] md:left-[-8%] rounded-[100%] bg-white md:max-h-[413px] w-full h-full"></div>
      <div className="gradient-decor md:hidden gradient-decor-low-blury max-h-[212px] max-w-[212px] absolute md:max-w-[413px] top-[20%] left-[40%] md:top-[-45%] md:left-[-8%] rounded-[100%] bg-white md:max-h-[413px] w-full h-full"></div>
      <div className="container__left">
        <div className="md:grid gap-[30px] gpm-travel-hero-section__content">
          <div>
            <h1 className="relative z-[3] leading-[112%] md:mx-0 mx-auto text-center md:text-left max-w-[288px] md:max-w-[535px] text-[28px] lg:text-[35px] xl:text-[48px] font-black">
              {t("gpm_travel.hero-section.title")}
            </h1>
            <p className="font-bold relative z-[3] text-center leading-[133%] md:text-left md:font-medium max-w-[288px] mx-auto md:mx-0 md:max-w-[449px] lg:text-[24px] mt-[20px] md:mt-[23px]">
              {t("gpm_travel.hero-section.text")}
            </p>
            <Button
              buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[92px] relative z-[3] transition-all md:block w-full text-center"
              buttonUrl="/GPM-link"
              buttonText={t("gpm_travel.hero-section.button")}
            />
          </div>
          <div className="overflow-hidden">
            <Swiper
              {...gallerySwiperParams}
              modules={[Autoplay]}
              className="gpm-travel-hero__slider mt-[43px] md:mt-0"
            >
              {gpmTravelHeroData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div>
                    <div className="relative">
                      <div className="relative">
                        <img
                          src={item.image}
                          className="sm:max-h-none w-full h-full object-cover rounded-[10px]"
                          alt="slide_location"
                        />
                      </div>
                      <h4 className="mt-[5px] md:mt-[15px] md:text-[14px] transition-all">
                        {t(`${item.country}`)}
                      </h4>
                      <img
                        src={item.rating}
                        alt="stars"
                        className="w-[initial] dark mt-[5px] md:mt-[10px]"
                      />
                      <img
                        src={item.ratingLight}
                        alt="stars"
                        className="w-[initial] light mt-[5px] md:mt-[10px]"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Button
            buttonClass="button md:hidden mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[33px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
            buttonText={t("gpm_travel.hero-section.button")}
            buttonUrl="#"
          />
        </div>
      </div>
    </section>
  );
}
