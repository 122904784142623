import { Button } from "../button";
import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useState } from "react";
import { Presale } from "../nft-presale";
import { Regular } from "../ntf-regular";
import arrowLeft from "../../media/images/nft-right-arrow.svg"
import arrowRight from "../../media/images/nft-left-arrow.svg"

export function NftTypes() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  useAos();

  return (
    <section className="mt-[70px] md:mt-[100px]">
      <div className="container nft__container">
        <h2
          data-aos="fade-right"
          className="block text-center font-extrabold text-[24px] relative z-[2] md:text-[28px] lg:text-[48px] leading-[125%]"
        >
          {t("gpm_token.nfs_types.title")}
        </h2>
        <p
          data-aos="fade-left"
          className="text-center text-[14px] mt-[15px] mb-[30px] md:text-[18px] lg:text-[20px]"
        >
          {t("gpm_token.nfs_types.text")}
        </p>
        <div className="flex w-fit mx-auto justify-center gap-[20px] mb-[20px] relative md:mb-0 md:gap-[39px]">
          <img src={arrowRight} alt="arrow" className={`absolute ntf-button__arrow  ${activeTab === 2 ? "active": ''} top-0 max-w-[60px] md:max-w-none rotate-[284deg] md:rotate-0 md:top-[-50%] right-[-36%] opacity-0 transition-all md:right-[-31%]`} />
          <button
            onClick={() => setActiveTab(1)}
            className={`${
              activeTab === 1 ? "active" : ""
            } nft-types__change-button md:text-[20px] max-w-[83px] font-extrabold py-[22px] md:py-[9.5px] md:max-w-[137px] w-full px-[7px] md:px-[29px]`}
          >
            Presale
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={`${
              activeTab === 2 ? "active" : ""
            }  nft-types__change-button md:text-[20px] max-w-[83px] font-extrabold py-[22px] md:py-[9.5px] md:max-w-[137px] w-full px-[7px] md:px-[29px]`}
          >
            Regular
          </button>
          <img src={arrowLeft} alt="arrow" className={`absolute ntf-button__arrow ${activeTab === 1 ? "active": ''} opacity-0 transition-all top-0 rotate-[-284deg] md:rotate-0 md:top-[-50%] left-[-36%] md:left-[-31%]`} />
        </div>
        {activeTab === 2 && (
          <Regular />
        )}
        {activeTab === 1 && (
          <Presale />
        )}

        <Button
          buttonClass="relative z-[4] button mx-auto transition-all block text-center text-[16px] md:text-[20px] mt-[44px] lg:mt-[58px] max-w-[190px] md:max-w-[247px] w-full"
          buttonText={t("gpm_token.nfs_types.button")}
          buttonUrl="#"
        />
      </div>
    </section>
  );
}
