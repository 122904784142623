import {gpmTravelHero} from '../models'

export const gpmTravelHeroData: gpmTravelHero[] = [
  {
    image: "images/location1.png",
    country: "location-slider.slide1.country",
    rating: "images/four-stars.svg",
    ratingLight: "images/four-stars-dark.svg",
    id: 1
  },
  {
    image: "images/location2.png",
    country: "location-slider.slide2.country",
    rating: "images/five-stars.svg",
    ratingLight: "images/five-stars-dark.svg",
    id: 2
  },
  {
    image: "images/location3.png",
    country: "location-slider.slide3.country",
    rating: "images/four-stars.svg",
    ratingLight: "images/four-stars-dark.svg",
    id: 3
  },
  {
    image: "images/location4.png",
    country: "location-slider.slide4.country",
    rating: "images/five-stars.svg",
    ratingLight: "images/five-stars-dark.svg",
    id: 4
  }
]