import ContactUs from "../components/sections/contact-form";
//import { FaqSection } from "../components/sections/faq-section";
import { GpmTravelHero } from "../components/sections/gpm-travel-hero";
import { NewsForm } from "../components/sections/newsletter";
import { Features } from "../components/sections/features";
import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { Header } from "../components/header";
import ScrollToTop from "../hooks/scrollToTop";

import { discoverData } from "../data/discover";
import { LocationSwiper } from "../components/sections/location-swiper";
import { GpmTravelBenefits } from "../components/sections/gpm-travel-benefits";

export function GpmTravel() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="main gpm-travel-page">
        <GpmTravelHero />
        <Features title={t("gpm_travel.discover.title")} data={discoverData} />
        <LocationSwiper
          title={t("gpm_travel.location-slider.title")}
          text={t("gpm_travel.location-slider.text")}
        />
        <GpmTravelBenefits />
        {/* 
      <FaqSection />
      */}
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
