import {features} from '../models'

export const tokenFeatures: features[] = [
  {
    image: "images/token-start1.png",
    title: "gpm_token.features.item1.title",
    id: 1,
  },
  {
    image: "images/token-start2.png",
    title: "gpm_token.features.item2.title",    
    id: 2
  },
  {
    image: "images/token-start3.png",
    title: "gpm_token.features.item3.title",
    id: 3
  },
]
