import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export function NewsForm() {
  const { t } = useTranslation();
  useAos();

  function sendEmail(e: any) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_njugrsn",
        "template_g9fcjbb",
        e.target,
        "WZXMN3LesK8A346Ss"
      )
      .then(
        (result) => {
        },
        (error) => {
        }
      );
  }
  return (
    <section className="news-form py-[35px] md:py-[70px]">
      <div className="container news-form__container">
        <div data-aos="fade-up" className="max-w-[300px] sm:max-w-[500px] md:max-w-[680px] px-[15px] md:px-[30px] py-[23px] md:py-[40px] rounded-[12px] transitioin-all bg-accentWhite/90 dark:bg-accentDark mx-auto">
          <h2 className="sm:max-w-none max-w-[150px] mx-auto sm:mx-0 text-center font-extrabold text-[22px] md:text-[32px] leading-[112%]">{t("news-form.title")}</h2>
          <form className="mt-[25px] md:mt-[48px] contact-form relative z-[2]" onSubmit={sendEmail}>
            <div className="flex relative right-[-10.7%] sm:right-[-6%] md:right-[-7%] items-center justify-between">
              <div className="w-full">
                <input
                  required
                  className="w-full bg-transparent py-[11px] news-form__input"
                  type="email"
                  placeholder={t("news-form.email")}
                  name="from_email"
                />
              <div className="gradient-border news-gradient-border"></div>
              </div>
              <button
                className="news-form__button relative button max-h-[46px] max-w-[90px] sm:max-w-[128px] w-full justify-center flex items-center"
                type="submit"
              >
                {t("news-form.button")}              
              </button>
            </div>
          </form>{" "}
        </div>
      </div>
    </section>
  );
}
