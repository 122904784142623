import { Button } from "../button";
import img from "../../media/images/token-hero.png";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function GpmTokenHero() {
  const { t } = useTranslation();
  return (
    <section className="hero-section h-full mt-[128px] md:mt-[205px] relative pb-[70px] md:pb-[128px]">
      <div className="hidden md:block gradient-decor absolute max-w-[413px] top-[-40%] left-[-8%] rounded-[100%] bg-white max-h-[413px] w-full h-full"></div>
      <div className="container h-full">
        <div className="grid md:grid-cols-2 lg:grid-cols-[1fr_.8fr] relative md:gap-[30px] lg:gap-0 z-[2]">
          <div className="text-center md:text-left">
            <h1 className="relative z-[2] mx-auto md:mx-0 leading-[111%] text-[28px] lg:text-[35px] xl:text-[48px] font-black max-w-[652px]">
              {t("gpm_token.hero-section.title")}
            </h1>
            <p className="font-bold relative z-[2] mx-auto md:mx-0 max-w-[464px] md:font-medium lg:text-[24px] md:mb-0 mt-[23px]">
              {t("gpm_token.hero-section.text")}
            </p>
            <Button
              buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[70px] lg:mt-[107px] relative z-[3] transition-all md:block w-full text-center"
              buttonUrl="#"
              buttonText={t("gpm_token.hero-section.button")}
            />
          </div>
          <div className="hero-section__right md:relative">
            <div className="gradient-decor hero-section-decor z-[1] absolute max-w-[200px] max-h-[200px] md:max-w-[250px] md:max-h-[250px] md:top-[50%] md:left-[50%] left-[69%] top-[33%] xl:max-w-[300px] opacity-80 rounded-[100%] bg-white xl:max-h-[300px] w-full h-full"></div>
            <div className="hero-section__image-wrapper">
              <img
                className="hero-section__image-mobile max-w-[400px] md:max-w-none mx-auto relative relative z-[2] w-full mx-auto min-h-[1px]"
                src={img}
                alt="hero_collage"
              />
            </div>
          </div>
        </div>
        <Button
          buttonClass="md:hidden button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[50px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
          buttonUrl="#"
          buttonText={t("gpm_token.hero-section.button")}
        />
      </div>
    </section>
  );
}
