import {footerLinks} from '../models'

export const footerLinks2: footerLinks[] = [
  {
    url: "#",
    text: "footer.link_list2.link1",
    id: 1,
  },
  {
    url: "#",
    text: "footer.link_list2.link2",
    id: 2
  }
]