import ContactUs from "../components/sections/contact-form";
//import { DiscountSwiper } from "../components/sections/discont-swiper";
import { FaqSection } from "../components/sections/faq-section";
import { HeroSection } from "../components/sections/hero-section";
//import { LocationSwiper } from "../components/sections/location-swiper";
import { NewsForm } from "../components/sections/newsletter";
//import { ThreeBackground } from "../components/sections/tree-background-section";
import { Header } from "../components/header";
import ScrollToTop from "../hooks/scrollToTop";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { Features } from "../components/sections/features";
import { GpmLinkRightImage } from "../components/sections/gpm-link-right-image";
//import { GpmLinkLefttImage } from "../components/sections/gpm-link-left-image";
//import { Chart } from "../components/sections/chart";
//import { Plans } from "../components/sections/plans";

import { linkFaq } from "../data/linkFaq";
import { featuresLinkData } from "../data/featuresLink";


export function GpmLinkPage() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="main gpm-link-page">
        <HeroSection
          button={`${t("gpm_link.hero-section.button")}`}
          text={`${t("gpm_link.hero-section.text")}`}
          title1={`${t("gpm_link.hero-section.title1")}`}
          title2={`${t("gpm_link.hero-section.title2")}`}
        />
        <Features title={t("featuresLink.title")} data={featuresLinkData} />
        {/* 
      <LocationSwiper title={t("location-slider.title")} text={t("location-slider.text")} />
      <DiscountSwiper />
      */}
        <GpmLinkRightImage
          title={t("gpm_link.image-right.title")}
          text={t("gpm_link.image-right.text")}
          button={t("gpm_link.image-right.button")}
        />
        {/* 
      <GpmLinkLefttImage title={t("gpm_link.image-left.title")} text={t("gpm_link.image-left.text")} button={t("gpm_link.image-left.button")} />
      <ThreeBackground />
      */}
        {/* 
      <Chart />
      <Plans />
      */}
        <FaqSection data={linkFaq} />
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
