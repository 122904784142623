import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { faqProps } from "../../models";

export function FaqSection(props: faqProps) {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="relative faq pb-[70px] owerflow-x-hidden pt-[70px] md:pt-[100px] lg:pt-[244px]">
      <div className="gradient-decor sm:block hidden z-[3] md:z-[1] absolute w-full h-full max-w-[200px] max-h-[200px] lg:max-w-[350px] lg:max-h-[300px] right-[-13%] top-[-25%] lg:top-[-5%]"></div>
      <div className="container">
        <h2
          data-aos="fade-right"
          className="uppercase relative z-[4] text-center md:text-left font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]"
        >
          {t("faq.title")}
        </h2>
        <div
          data-aos="fade-left"
          className="faq__spoilers mt-[44px] md:mt-[48px] relative z-[2]"
        >
          {props.data.map((spoiler: any) => (
            <div key={spoiler.id}>
              <details open={spoiler.spoilerState} className="py-[19px] md:py-[31px] faq__spoiler">
                <summary className="flex items-center justify-between font-extrabold text-[16px] md:text-[18px] lg:text-[24px]">
                  {t(`${spoiler.title}`)}
                  <div className="relative">
                    <span className="block w-[17px] h-[3px] bg-accentDark dark:bg-accentWhite"></span>
                    <span className="spoiler__arrow block absolute top-0 rotate-[90deg] w-[17px] h-[3px] transition-all bg-accentDark dark:bg-accentWhite"></span>
                  </div>
                </summary>
                <p className="text-[12px] leading-[150%] sm:text-[14px] md:text-[16px] max-w-[256px] md:max-w-[500px] md:max-w-[875px] mt-[13px] md:mt-[25px]">
                  {t(`${spoiler.text}`)}
                </p>
              </details>
              <div className="gradient-border faq-gradient-border"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
