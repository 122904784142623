import {ntfTypes} from '../models'

export const ntfTypesDataRegular: ntfTypes[] = [
  {
    image: "images/disable.jpeg",
    text: "NFT Level 1",
    price: "50 $BUSD",
    percent: "+20% per year",
    cssClass: "static-image",
    id: 1,
  },
  {
    image: "images/pre(2).gif",
    text: "NFT Level 2",
    price: "110 $BUSD",
    percent: "+40% per year",
    cssClass: "gif-image",
    id: 2,
  },
  {
    image: "images/pre3(2).gif",
    text: "NFT Level 3",
    price: "231 $BUSD",
    percent: "+80% per year",
    cssClass: "gif-image",
    id: 3,
  },
  {
    image: "images/disable.jpeg",
    text: "NFT Level 4",
    price: "485 $BUSD",
    percent: "+100% per year",
    cssClass: "static-image",
    id: 4,
  },
  {
    image: "images/pre4(2).gif",
    text: "NFT Level 5",
    price: "1 019 $BUSD",
    percent: "+120% per year",
    cssClass: "gif-image",
    id: 5,
  },
  {
    image: "images/disable.jpeg",
    text: "NFT Level 6",
    price: "2 139 $BUSD",
    percent: "+150% per year",
    cssClass: "static-image",
    id: 6,
  },
  {
    image: "images/pre5(2).gif",
    text: "NFT Level 7",
    price: "4 493 $BUSD",
    percent: "+200% per year",
    cssClass: "gif-image",
    id: 7,
  },
  {
    image: "images/disable.jpeg",
    text: "NFT Level 8",
    price: "9 434 $BUSD",
    percent: "+240% per year",
    cssClass: "static-image",
    id: 8,
  },
  {
    image: "images/disable.jpeg",
    text: "NFT Level 9",
    price: "19 812 $BUSD",
    percent: "+280% per year",
    cssClass: "static-image",
    id: 9,
  },
  {
    image: "images/pre6(2).gif",
    text: "NFT Level 10",
    price: "41 605 $BUSD",
    percent: "+300% per year",
    cssClass: "gif-image",
    id: 10,
  }
]