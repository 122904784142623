import { Button } from "../button";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export function DashboardReseller(){
  const { t } = useTranslation();
  useAos();
  return(
    <section className="chart z-[3] relative pb-[70px] md:pt-[164px] md:pb-[118px] text-center">
      <div className="gradient-decor absolute md:hidden z-[1] max-w-[220px] max-h-[220px] w-full h-full bottom-[10%] left-[-10%]"></div>
      <div className="container">
        <div data-aos="fade-right" className="relative z-[3]">
          <h2 className="max-w-[288px] mx-auto font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] md:max-w-[537px] mx-auto leading-[125%]">
            {t("gpm_reseller.dashboard.title")}
          </h2>
          <p className="max-w-[288px] mb-[23px] md:max-w-[680px] mx-auto mt-[20px] text-[14px] md:text-[18px] leading-[167%]">
            {t("gpm_reseller.dashboard.text")}
          </p>
          <dotlottie-player
            src="lottie/dashboard.lottie"
            autoplay
            loop
            class="mx-auto max-w-[700px] mx-auto"
            style={{ height: "100%", width: "100" }}
          />
          <Button
            buttonClass="button mx-auto mt-[23px] transition-all block text-center text-[16px] md:text-[20px] max-w-[190px] md:max-w-[247px] w-full"
            buttonText={t("gpm_reseller.dashboard.button")}
            buttonUrl="#"
          />
        </div>
      </div>
    </section>
  )
}