import { ProductLinksLocal } from "../../data/product-menu";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { NavLink } from "react-router-dom";
import { menuProps } from "../../models";


export function SubMenu(props: menuProps) {
  const { t } = useTranslation();
  return (
    <div className="submenu__wrapper">
      <div className="submenu__body z-[2] overflow-hidden absolute w-[510px]">
        <div className="gradient-decor gradient-decor__sub-menu gradient-decor opacity-20 absolute z-[2] rounded-[100%] w-full h-full"></div>
        <div className="submenu grid bg-[#F8FFFF] dark:bg-accentDark grid-cols-2 transition gap-2.5 p-3.5 rounded-bl-xl rounded-br-xl">
          {ProductLinksLocal.map((link) => (
            <NavLink
              onClick={props.click}
              key={link.id}
              to={link.url}
              className={`submenu__link dark:bg-[#242331] min-h-[68px] rounded-[6px] z-[2] py-2.5 px-[21px] hover:text-purple relative z-[10] transition flex rounded-[6px] items-center`}
            >
              <img
                src={link.image}
                className="mr-[17px] min-h-[23px] min-w-[17px]"
                alt="product_ico"
              />
              {t(`${link.text}`)}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
}
