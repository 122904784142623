import {discontSlide} from '../models'

export const regularSlides: discontSlide[] = [
  {
    image: "images/clock.png",
    title: "discont-slider.slide1.title",
    subtitle: "discont-slider.slide1.subtitle",
    discountPrice: "discont-slider.slide1.discountPrice",
    price: "2 250 €",
    percent: "discont-slider.slide1.percent",
    id: 1
  },
  {
    image: "images/star.png",
    title: "discont-slider.slide3.title",
    subtitle: "discont-slider.slide3.subtitle",
    discountPrice: "discont-slider.slide3.discountPrice",
    price: "374 €",
    percent: "discont-slider.slide3.percent",
    id: 2
  },
  {
    image: "images/package.png",
    title: "discont-slider.slide4.title",
    subtitle: "discont-slider.slide4.subtitle",
    discountPrice: "discont-slider.slide4.discountPrice",
    price: "1 160 €",
    percent: "discont-slider.slide4.percent",
    id: 3
  }
]