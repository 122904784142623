import { ButtonLink } from "../buttonLink";
import img from "../../media/images/man.png"

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function DigitalGrid(){
  const { t } = useTranslation();
  useAos();
  return(
    <section className="image-right digital-image-right relative md:overflow-hidden pt-[70px] pb-[70px] md:pt-[157px] md:pb-[130px]">
      <div className="gradient-decor max-w-[200px] max-h-[200px] bottom-0 right-[-10%] absolute md:max-w-[412px] md:max-h-[412px] w-full h-full md:right-[-20%] md:top-[-20%]"></div>
      <div className="container">
        <div className="md:grid relative z-[2] grid-cols-2 md:gap-[70px] items-center">
          <div data-aos="fade-up">
            <h2 className="font-extrabold mb-[23px] md:mb-[0] relative z-[2] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{t("digital_team.digital_grid.title")}</h2>
            <div data-aos="fade-left" className="relative">
              <img src={img} alt="man" className="md:hidden relative z-[2] rounded-[12px]" />
            </div>
            <p className="mt-[25px] relative z-[2] lg:mt-[27px] mx-auto md:mx-0 text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] max-w-[499px] leading-[167%]">{t("digital_team.digital_grid.text")}</p>
            <ButtonLink buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[32px] lg:mt-[51px] max-w-[190px] md:max-w-[247px] w-full" buttonText={t("digital_team.digital_grid.button")} buttonUrl="https://calendly.com/davidgpm/30min" />
          </div>
          <div data-aos="fade-left" className="relative">
            <img src={img} alt="graph" className="hidden md:block relative z-[2] rounded-[12px]" />
          </div>
        </div>
      </div>
      <div className="gradient-decor absolute max-w-[220px] w-full h-full max-h-[220px] z-[1] block md:hidden top-0 md:bottom-[-14%] left-[-14%]"></div>
    </section>
  )
}