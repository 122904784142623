import { useState, useEffect } from "react";

export function useThemeSwitcher() {
  const [whiteMode, setWhiteMode] = useState(false);
  
  const body = document.body;
  if (whiteMode === true) {
    body.setAttribute("themeStyle", "white");
  } else {
    body.setAttribute("themeStyle", "dark");
  }

  function modeChange() {
    setWhiteMode(!whiteMode);
  }

  useEffect(() => {
    const data = window.localStorage.getItem("theme_mode_state");
    if (data !== null) setWhiteMode(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("theme_mode_state", JSON.stringify(whiteMode));
  }, [whiteMode]);

  return { modeChange, whiteMode};
}
