import { FaqSection } from "../components/sections/faq-section";
import ContactUs from "../components/sections/contact-form";
import { NewsForm } from "../components/sections/newsletter";
import { GpmResellerHero } from "../components/sections/gpm-reseller-hero";
import { Features } from "../components/sections/features";

import { featuresResellerData } from "../data/features-reseller";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { DashboardReseller } from "../components/sections/dashboard-reseller";
//import { Percents } from "../components/sections/percents";
import { ImageRightReseller } from "../components/sections/image-right-reseller";
import { faqSpoilers } from "../data/spoilers";
import { Header } from "../components/header";
import ScrollToTop from "../hooks/scrollToTop";

export function GpmReseller() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <ScrollToTop />
      <main className="main reseller-page">
        <GpmResellerHero />
        <Features className="resellerFeatures" title={t("featuresReseller.title")} data={featuresResellerData} />
        <DashboardReseller />
        {/* <Percents /> */}
        <ImageRightReseller />
        <FaqSection data={faqSpoilers} />
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
