import {spoilers} from '../models'

export const tokenFaq: spoilers[] = [
  {
    title: "token-faq.item1.title",
    text: "token-faq.item1.text",
    id: 1,
    spoilerState: true
  },
  {
    title: "token-faq.item2.title",
    text: "token-faq.item2.text",
    id: 2,
    spoilerState: false
  },
  {
    title: "token-faq.item3.title",
    text: "token-faq.item3.text",
    id: 3,
    spoilerState: false
  },
  {
    title: "token-faq.item4.title",
    text: "token-faq.item4.text",
    id: 4,
    spoilerState: false
  },
  {
    title: "token-faq.item5.title",
    text: "token-faq.item5.text",
    id: 5,
    spoilerState: false
  },
  {
    title: "token-faq.item6.title",
    text: "token-faq.item6.text",
    id: 6,
    spoilerState: false
  }
]