import {features} from '../models'

export const discoverData: features[] = [
  {
    image: "images/discover1.svg",
    title: "gpm_travel.discover.item1.title",
    text: "gpm_travel.discover.item1.text",    
    id: 1,
  },
  {
    image: "images/discover2.svg",
    title: "gpm_travel.discover.item2.title",    
    text: "gpm_travel.discover.item2.text",    
    id: 2
  },
  {
    image: "images/discover3.svg",
    title: "gpm_travel.discover.item3.title",
    text: "gpm_travel.discover.item3.text",    
    id: 3
  },
]
