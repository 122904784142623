import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import "swiper/css";
import { mailDiscontSlideData } from "../../data/discount-slider-mail";
import { regularSlides } from "../../data/discont-slider-regular";
import { pointsSlides } from "../../data/discont-slider-points";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Button } from "../button";
import { useState } from "react";

import arrow from "../../media/images/gradient-arrow.svg"

export function MailTabs() {
  const { t } = useTranslation();
  const [buttonOneState, buttonOneSetState] = useState(false);
  const [buttonTwoState, buttonTwoSetState] = useState(true);
  const [buttonThreeState, buttonThreeSetState] = useState(false);

  useAos();

  const gallerySwiperParams: SwiperProps = {
    watchOverflow: true,
    slidesPerView: 1.5,
    centeredSlides: true,
    autoHeight: true,
    spaceBetween: 30,
    breakpoints: {
      900: {
        slidesPerView: 3,
        centeredSlides: false,
      },
      760: {
        slidesPerView: 2.7,
      },
      640: {
        slidesPerView: 2.2,
      },
      600: {
        slidesPerView: 2.2,
      },
      478: {
        slidesPerView: 1.8,
      },
    },
  };
  return (
    <section className="pb-[70px] overflow-hidden md:pb-[142px] left-image left-image-digital relative pt-[122px] md:pt-[159px] mail-tabs">
      <div className="gradient-decor-tabs absolute max-w-[219px] max-h-[219px] md:max-w-[412px] md:max-h-[412px] md:right-[-20%] md:top-[7%] w-full h-full"></div>
      <div className="container">
        <h2
          data-aos="fade-right"
          className="font-extrabold relative z-[2] text-center max-w-[745px] leading-[125%] mx-auto text-[24px] md:text-[30px] lg:text-[40px]"
        >
          {t("tabs.title")}
        </h2>
        <div className="flex relative z-[2] justify-center gap-[20px] mt-[43px] md:mt-[85px]">
          <button
            className={`button-tab transition-all ${
              buttonOneState ? "active-tab1" : ""
            }`}
            onClick={() => {
              buttonOneSetState(true);
              buttonTwoSetState(false);
              buttonThreeSetState(false);
            }}
          >
            {t("tabs.button1")}
          </button>
          <button
            className={`button-tab relative transition-all ${
              buttonTwoState ? "active-tab2" : ""
            }`}
            onClick={() => {
              buttonTwoSetState(true);
              buttonOneSetState(false);
              buttonThreeSetState(false);
            }}
          >
            {t("tabs.button2")}
            <img src={arrow} alt="arrow" className="mobile-arrow hidden absolute top-[68%] left-[-30%]" />
          </button>
          <button
            className={`button-tab transition-all ${
              buttonThreeState ? "active-tab3" : ""
            }`}
            onClick={() => {
              buttonOneSetState(false);
              buttonTwoSetState(false);
              buttonThreeSetState(true);
            }}
          >
            {t("tabs.button3")}
          </button>
        </div>
      </div>
      <div  data-aos="fade-left" className="container container-tabs">
        {buttonOneState && (
          <Swiper
            className="location-slider slider-1 tab-slider relative z-[2] discount-slider"
            {...gallerySwiperParams}
          >
            {regularSlides.map((item) => (
              <SwiperSlide key={item.id}>
                <div>
                  <div className="relative w-full discount-slider__image">
                    <img
                      src={item.image}
                      className="sm:max-h-none w-full h-full object-cover transition-all"
                      alt="slide-product"
                    />
                  </div>
                  <div className="flex flex-col h-full">
                  <h3 className="text-[18px] sm:text-[20px] sm:text-[18px] mt-[10px] text-accentWhite font-bold">
                    {t(`${item.title}`)}
                  </h3>
                  <div className="flex mt-[14px] sm:mt-[23px] items-center justify-end">
                    <p className="text-[20px] font-extrabold text-accentWhite sm:text-[24px]">
                      {t(`${item.price}`)}
                    </p>
                  </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {buttonTwoState && (
          <Swiper
            className="location-slider slider-2 tab-slider  relative z-[2] discount-slider"
            {...gallerySwiperParams}
          >
            {mailDiscontSlideData.map((item) => (
              <SwiperSlide key={item.id}>
                <div>
                  <div className="relative w-full discount-slider__image">
                    <img
                      src={item.image}
                      className="sm:max-h-none w-full h-full object-cover transition-all"
                      alt="slide-product"
                    />
                    <p className="sm:w-[60px] w-[42px] h-[42px] absolute sm:top-[-6%] top-[-3%] right-[-3%] sm:right-[-6%] font-bold text-[14px] sm:text-[20px] sm:h-[60px] discount-percent flex items-center justify-center rounded-[50%] bg-[#FF4A6B]">
                      {t(`${item.percent}`)}
                    </p>
                  </div>
                  <div className="flex flex-col h-full">
                  <h3 className="text-[18px] sm:text-[20px] sm:text-[18px] mt-[10px] text-accentWhite font-bold">
                    {t(`${item.title}`)}
                  </h3>
                  <div className="flex mt-[14px] sm:mt-[23px] items-center justify-between">
                    <p className="text-[#B1B7E7] font-extrabold item-price text-accentWhite relative before:absolute before:top-[50%] before:translate-y-[-50%] before:content-[''] before:w-full before:h-[2px] before:bg-[#B1B7E7] text-[16px] sm:text-[20px]">
                      {t(`${item.discountPrice}`)}
                    </p>
                    <p className="text-[20px] font-extrabold text-accentWhite sm:text-[24px]">
                      {t(`${item.price}`)}
                    </p>
                  </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {buttonThreeState && (
          <Swiper
            className="location-slider slider-3 tab-slider  relative z-[2] discount-slider"
            {...gallerySwiperParams}
          >
            {pointsSlides.map((item) => (
              <SwiperSlide key={item.id}>
                <div>
                  <div className="relative w-full discount-slider__image">
                    <img
                      src={item.image}
                      className="sm:max-h-none w-full h-full object-cover transition-all"
                      alt="slide-product"
                    />
                    <p className="sm:w-[60px] w-[42px] h-[42px] absolute sm:top-[-6%] top-[-3%] right-[-3%] sm:right-[-6%] font-bold text-[14px] sm:text-[20px] sm:h-[60px] discount-percent flex items-center justify-center rounded-[50%] bg-[#FF4A6B]">
                      {t(`${item.percent}`)}
                    </p>
                  </div>
                  <div className="flex flex-col h-full">
                  <h3 className="text-[18px] sm:text-[20px] sm:text-[18px] mt-[10px] text-accentWhite font-bold">
                    {t(`${item.title}`)}
                  </h3>
                  <div className="flex mt-[14px] sm:mt-[23px] items-center justify-between">
                    <p className="text-[#B1B7E7] font-extrabold item-price text-accentWhite relative before:absolute before:top-[50%] before:translate-y-[-50%] before:content-[''] before:w-full before:h-[2px] before:bg-[#B1B7E7] text-[16px] sm:text-[20px]">
                      {t(`${item.discountPrice}`)}
                    </p>
                    <p className="text-[20px] font-extrabold text-accentWhite sm:text-[24px]">
                      {t(`${item.price}`)}
                    </p>
                  </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div  data-aos="fade-right" className="container">
        <Button
          buttonClass="button relative z-[2] transition-all block text-center text-[16px] md:text-[20px] mx-auto mt-[52px] lg:mt-[59px] max-w-[190px] md:max-w-[247px] w-full"
          buttonText={t("tabs.button")}
          buttonUrl="/GPM-Token"
        />
      </div>
    </section>
  );
}
