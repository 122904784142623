import { Button } from "../button";

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function ImageLeft() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="left-image relative pt-[70px] overflow-hidden pb-[70px] md:py-[126px]">
      <div className="container">
      <h2 data-aos="fade-right" className="font-extrabold text-center max-w-[659px] mx-auto text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{t("image-left.title")}</h2>
        <div className="grid gap-[30px] md:gap-[60px] items-center relative z-[2] md:grid-cols-2">
          <div className="relative">
            <img src="images/pre(2).gif" alt="token_image" className="w-full md:max-w-none max-w-[400px] mx-auto md:mx-0" />
          </div>
          <div data-aos="fade-left" className="relative md:mt-[40px] z-[2]">
          <p className="text-center md:text-left mx-auto md:mx-0 max-w-[499px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">{t("image-left.text")}</p>
            <Button
              buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[30px] lg:mt-[85px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("image-left.button")}
              buttonUrl="/GPM-Token"
            />
          </div>
        </div>
      </div>
      <div className="gradient-decor absolute w-full h-full md:block hidden max-w-[350px] lg:max-w-[450px] bottom-[-25%] right-[-7%] z-[1] max-h-[350px] lg:max-h-[450px]"></div> 
    </section>
  );
}
