import dark from "../../media/images/dark.svg"
import white from "../../media/images/white.svg"
import { useThemeSwitcher } from "../../hooks/themeSwitcher"

export function ThemeSwitcher() {
  const {modeChange, whiteMode} = useThemeSwitcher()
  return (
    <button onClick={modeChange} className="md:mr-[15px] mt-[5px] relative lg:mr-[28px] w-[53px] relative z-[21]">
        <img src={white} alt="dark" className={`absolute theme-style top-0 left-0 opacity-0 ${whiteMode ? 'current-style': '' }`} />
        <img src={dark} alt="white" className={`absolute theme-style top-0 left-0 opacity-0 ${!whiteMode ? 'current-style': '' }`} />
    </button>
  );
}