import {footerLinks} from '../models'

export const footerLinks1: footerLinks[] = [
  {
    url: "/GPM-Link",
    text: "footer.link_list1.link1",
    id: 1,
  },
  {
    url: "/Digital-Team",
    text: "footer.link_list1.link2",
    id: 2
  },
  {
    url: "/GPM-Mail",
    text: "footer.link_list1.link3",
    id: 3
  },
  {
    url: "GPM-Travel",
    text: "footer.link_list1.link4",
    id: 4
  },
  {
    url: "GPM-Token",
    text: "footer.link_list1.link5",
    id: 5
  },
  {
    url: "/GPM-Reseller",
    text: "footer.link_list1.link6",
    id: 6
  }
]