import "@dotlottie/player-component";

import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { Typewriter } from "react-simple-typewriter";
import { Button } from "../button";

export function BuildProfile() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="build-profile relative pt-[70px] md:pt-[100px]">
      <div className="gradient-decor profile-decor z-[1] absolute w-full h-full max-w-[215px] max-h-[215px] lg:max-w-[300px] lg:max-w-[300px] lg:max-w-[412px] lg:max-h-[412px] top-[-14%] right-[-15%] md:top-[-10%] md:right-[-10%]"></div>
      <div className="container">
        <h2 className="text-center relative z-[2] font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%] md:mb-[15px] max-w-[308px] md:max-w-[499px] mx-auto">
          {t("profile.title")}
        </h2>
        <p className="text-center mt-[5px]">
          {t("profile.subtext")}{" "}
          <Typewriter
            words={[
              `${t("hero-section.dynamic_title1")}`,
              `${t("hero-section.dynamic_title2")}`,
            ]}
            loop={false}
            typeSpeed={100}
            deleteSpeed={100}
            delaySpeed={1000}
          />
          {" "}
          Page
        </p>
        <div className="relative z-[2]">
          <div className="hidden md:block">
            <dotlottie-player
              src="lottie/profile-dark.lottie"
              autoplay
              renderer="svg"
              loop
              class="hidden dark:block"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="hidden md:block">
            <dotlottie-player
              src="lottie/profile-light.lottie"
              autoplay
              loop
              class="dark:hidden"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="md:hidden">
            <dotlottie-player
              src="lottie/profile-mobile.lottie"
              autoplay
              loop
              class="dark:block hidden"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="md:hidden">
            <dotlottie-player
              src="lottie/profile-lottie-light-mobile.lottie"
              autoplay
              loop
              class="dark:hidden"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <p className="text-center md:hidden text-[20px] font-bold">
            {t("profile.text")}
          </p>
          <div className="md:absolute w-full md:bottom-[8%] md:left-[38%]">
            <Button
              buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[46px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("profile.button")}
              buttonUrl="/GPM-Link"
            />
          </div>
        </div>
      </div>
      <div className="bottom-decor z-[1] absolute w-full h-[400px] right-0 bottom-[-20%] max-h-[400px]"></div>
    </section>
  );
}
