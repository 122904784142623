import { discontSlideData } from "../../data/discont-slider";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Pagination, Thumbs, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { useState } from "react";
import { Button } from "../button";

export function DiscountSwiper() {
  const [activeTumbLoc, setActiveTumbLoc] = useState<any>(null);

  const gallerySwiperParams: SwiperProps = {
    spaceBetween: 10,
    autoHeight: true,
    pagination: {
      el: ".swiper-pagination-discont",
      clickable: true,
    },
  };
  const thumbnailSwiperParams: SwiperProps = {
    autoHeight: true,
    direction: "vertical",
    slidesPerView: 4,
    slideToClickedSlide: true,
  };

  const { t } = useTranslation();
  useAos();
  return (
    <section className="discont-swiper relative pt-[70px] pb-[70px] md:pb-0 md:pt-[100px]">
      <div className="hidden md:block gradient-decor w-full h-full max-w-[413px] max-h-[413px] absolute right-[-15%] top-[79%] z-[1]"></div>
      <div className="container location-swiper__container">
        <h2
          data-aos="fade-right"
          className="px-[28px] mx-auto max-w-[340px] md:max-w-none md:p-0 mb-[40px] text-center mx-auto font-extrabold text-[24px] relative z-[2] md:text-[30px] lg:text-[40px] leading-[125%]"
        >
          {t("discont-slider.title")}
        </h2>
        <div className="grid grid-cols-1 mt-[70px] lg:grid-cols-2 md:gap-[30px] lg:gap-[50px] items-center relative z-[2]">
          <div
            data-aos="fade-right"
            className="flex justify-center lg:justify-start items-center h-full"
          >
            <div className="swiper-pagination-discont"></div>
            <Swiper
              modules={[Thumbs, EffectFade, Pagination]}
              effect="fade"
              className="location-slider discount-slider"
              thumbs={{ swiper: activeTumbLoc }}
              {...gallerySwiperParams}
            >
              {discontSlideData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div>
                    <div className="relative w-full discount-slider__image">
                      <img
                        src={item.image}
                        className="sm:max-h-none w-full h-full object-cover transition-all"
                        alt="slide-product"
                      />
                      <p className="sm:w-[60px] w-[42px] h-[42px] absolute sm:top-[-6%] top-[-3%] right-[-3%] sm:right-[-6%] font-bold text-[14px] sm:text-[20px] sm:h-[60px] discount-percent flex items-center justify-center rounded-[50%] bg-[#FF4A6B]">
                        {t(`${item.percent}`)}
                      </p>
                    </div>
                    <h3 className="text-[14px] sm:text-[20px] sm:text-[18px] mt-[10px] text-accentWhite font-bold">
                      {t(`${item.title}`)}
                    </h3>
                    <h3 className="text-accentWhite text-[12px] sm:text-[16px]">
                      {t(`${item.subtitle}`)}
                    </h3>
                    <div className="flex mt-[14px] sm:mt-[23px] items-center justify-between">
                      <p className="text-[#B1B7E7] font-extrabold item-price text-accentWhite relative before:absolute before:top-[50%] before:translate-y-[-50%] before:content-[''] before:w-full before:h-[2px] before:bg-[#B1B7E7] text-[14px] sm:text-[20px]">
                        {t(`${item.discountPrice}`)}
                      </p>
                      <p className="text-[18px] font-extrabold text-accentWhite sm:text-[22px]">
                        {t(`${item.price}`)}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              modules={[Thumbs]}
              onSwiper={setActiveTumbLoc}
              className="thumbs discont-thumbs"
              {...thumbnailSwiperParams}
            >
              {discontSlideData.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="relative">
                    <img
                      src={item.thumb}
                      alt="slide-thumb"
                      className="rounded-[6px]"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div data-aos="fade-left" className="grid-row-2 flex flex-col h-full">
            <p className="px-[28px] mb-[36px] lg:mb-[40px] md:p-0 mt-[36px] lg:mt-[10px] mx-auto lg:mx-0 text-center lg:text-left lg:mt-[47px] max-w-[499px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">
              {t("discont-slider.text")}
            </p>
            <Button
              buttonClass="button mt-auto mx-auto lg:mx-0 transition-all block text-center text-[16px] md:text-[20px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("discont-slider.button")}
              buttonUrl="#"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
