import { ButtonLink } from "../buttonLink";
import { useAos } from "../../utils/aos";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function DigitalImageRight() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="image-right relative overflow-hidden pt-[70px] pb-[70px] md:py-[126px]">
      <div className="container">
        <div className="md:grid relative z-[2] md:grid-cols-2 lg:grid-cols-[1fr_0.8fr] gap-[29px] items-center">
          <div data-aos="fade-up">
            <h2 className="font-extrabold mb-[32px] md:mb-[0] relative z-[2] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">
              {t("digital_team.image-right.title")}
            </h2>
            <div data-aos="fade-left" className="relative">
              <div className="gradient-decor absolute left-[50%] z-[1] opacity-40  translate-self top-[50%] w-[70%] h-[80%]"></div>
              <dotlottie-player
                src="lottie/dark-graphic.lottie"
                autoplay
                loop
                class="md:hidden dark relative z-[2]"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  zIndex: "2",
                }}
              />
              <dotlottie-player
                src="lottie/light-graphic.lottie"
                autoplay
                loop
                class="md:hidden light dark:hidden relative z-[2]"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  zIndex: "2",
                }}
              />
            </div>
            <p className="mt-[33px] relative z-[2] lg:mt-[32px] mx-auto md:mx-0 text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] max-w-[499px] leading-[167%]">
              {t("digital_team.image-right.text")}
            </p>
            <ButtonLink
              buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[38px] lg:mt-[48px] max-w-[190px] md:max-w-[247px] w-full"
              buttonText={t("digital_team.image-right.button")}
              buttonUrl="https://calendly.com/davidgpm/30min"
            />
          </div>
          <div data-aos="fade-left" className="relative">
            <div className="gradient-decor absolute left-[50%] z-[1] opacity-40 translate-self top-[50%] w-[70%] h-[80%]"></div>
            <dotlottie-player
              src="lottie/dark-graphic.lottie"
              autoplay
              loop
              class="hidden md:block dark relative z-[2]"
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
                zIndex: "2",
              }}
            />
            <dotlottie-player
              src="lottie/light-graphic.lottie"
              autoplay
              loop
              class="hidden md:block light dark:hidden relative z-[2]"
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
                zIndex: "2",
              }}
            />
          </div>
        </div>
      </div>
      <div className="gradient-decor absolute max-w-[220px] w-full h-full max-h-[220px] z-[1] block md:hidden bottom-[-14%] left-[-14%]"></div>
    </section>
  );
}
