import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { ButtonLink } from "../buttonLink";

export function GpmResellerHero(){
  const { t } = useTranslation();
  return(
    <section className="hero-section h-full mt-[70px] md:mt-[167px] relative pb-[70px] md:pb-[149px]">
    <div className="hidden md:block gradient-decor absolute max-w-[413px] top-[-40%] left-[-8%] rounded-[100%] bg-white max-h-[413px] w-full h-full"></div>
    <div className="container h-full">
      <div className="grid md:grid-cols-2 items-center md:gap-[36px] relative z-[2]">
        <div className="text-center md:text-left">
          <h1 className="relative z-[2] mx-auto md:mx-0 leading-[111%] text-[28px] lg:text-[35px] xl:text-[48px] font-black max-w-[652px]">
            {t("gpm_reseller.hero-section.title")}
          </h1>
          <p className="font-bold relative z-[2] md:font-medium lg:text-[24px] md:mb-0 mt-[23px]">
            {t("gpm_reseller.hero-section.text")}
          </p>
          <ButtonLink
            buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[67px] relative z-[3] transition-all md:block w-full text-center"
            buttonUrl="https://calendly.com/davidgpm/30min?month=2023-04"
            buttonText={t("gpm_reseller.hero-section.button")}
          />
        </div>
        <div className="hero-section__right md:relative mt-[32px] md:mt-[30px] md:mt-0">
          <div className="gradient-decor hero-section-decor z-[1] absolute max-w-[350px] max-h-[350px] md:max-w-[250px] md:max-h-[250px] md:top-[50%] md:left-[50%] left-[69%] top-[33%] xl:max-w-[460px] opacity-80 rounded-[100%] bg-white xl:max-h-[460px] w-full h-full"></div>
          <div className="hero-section__image-wrapper">
            <dotlottie-player
              src="lottie/token-hero-section.json"
              autoplay
              class="hero-section__image-mobile max-w-[400px] md:max-w-none mx-auto relative relative z-[2] w-full mx-auto min-h-[1px]"
              loop
              style={{ height: "100%", width: "100%", position: "relative", zIndex: "2" }}
            />
          </div>
        </div>
      </div>
      <ButtonLink
        buttonClass="md:hidden button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[38px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
        buttonUrl="https://calendly.com/davidgpm/30min?month=2023-04"
        buttonText={t("gpm_reseller.hero-section.button")}
      />
    </div>
  </section>
  )
}