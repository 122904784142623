import enFlag from "../../media/images/en-flag.svg";
import frFlag from "../../media/images/france-flag.svg";
import espFlag from "../../media/images/spain-flag.svg";

import i18n from "../../utils/i18next";
import { useLangSwitcher } from "../../hooks/langSwitcher";

export function FooterLang() {
  const { changeLanguage } = useLangSwitcher();
  return (
    <div className="p-[20px] top-[40px] left-[-20px] footer-lang-wrapper w-fit absolute z-[2] bg-accentWhite dark:bg-accentDark">
      <div
        className={`cursor-pointer dark:text-accentWhite text-[12px] md:text-[16px] text-accentDark flex items-center ${i18n.languages[0] === "en" ? "_current-lang" : ""}`}
        onClick={() => changeLanguage("en")}
      >
        <img src={enFlag} alt="en_flag" className="max-w-[26px] max-h-[19px] mr-[12px]" />
        English
      </div>
      <div
        className={`cursor-pointer flex items-center dark:text-accentWhite text-[12px] md:text-[16px] mt-[12px] ${i18n.languages[0] === "fr" ? "_current-lang" : ""}`}
        onClick={() => changeLanguage("fr")}
      >
        <img src={frFlag} alt="fr_flag" className="max-w-[26px] max-h-[19px] mr-[12px]" />
        Français
      </div>
      <div
        className={`cursor-pointer flex dark:text-accentWhite text-[12px] md:text-[16px] items-center mt-[12px] ${i18n.languages[0] === "esp" ? "_current-lang" : ""}`}
        onClick={() => changeLanguage("esp")}
      >
        <img src={espFlag} alt="spain_flag" className="max-w-[26px] max-h-[19px] mr-[12px]" />
        Español
      </div>
    </div>
  );
}
