import { Suspense } from "react";
import { Footer } from "./components/footer";
import { FooterMobile } from "./components/footer/footer-mobile";
import { Route, Routes } from "react-router-dom";
import { GpmLinkPage } from "./pages/gpmLinkPage";
import { HomePage } from "./pages/homePage";
import { DigitalTeam  } from "./pages/digitalTeam";
import { GpmMail } from "./pages/gpmMail";
import { GpmTravel } from "./pages/gpmTravel";
import { GpmReseller } from "./pages/gpmReseller";
import { GpmToken } from "./pages/gpmToken";


function App() {
  return (
    <>
      <Suspense>
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="GPM-Link" element={<GpmLinkPage />} />
            <Route path="Digital-Team" element={<DigitalTeam />} />
            <Route path="GPM-Mail" element={<GpmMail />} />
            <Route path="GPM-Travel" element={<GpmTravel />} />
            <Route path="GPM-Token" element={<GpmToken />} />
            <Route path="GPM-Reseller" element={<GpmReseller />} />
          </Routes>
          <Footer />
          <FooterMobile />
        </div>
      </Suspense>
    </>
  );
}

export default App;
