import enFlag from "../../media/images/en-flag.svg";
import frFlag from "../../media/images/france-flag.svg";
import espFlag from "../../media/images/spain-flag.svg";
import i18n from "../../utils/i18next";

import { useLangSwitcher } from "../../hooks/langSwitcher";
import { useState, useEffect, useRef } from "react";

export function LangSwitcher() {
  const [langListShow, setLangListShow] = useState(false);
  let langRef = useRef<any>();
  const { changeLanguage } = useLangSwitcher();

  function langEn(){
    changeLanguage("en");
    setLangListShow(false);
  }

  function langFr(){
    changeLanguage("fr");
    setLangListShow(false);
  }

  function langSp(){
    changeLanguage("esp");
    setLangListShow(false);
  }

  useEffect(() => {
    const node = langRef.current;
    let handler = (e: any) => {
      if (!node?.contains(e.target)) {
        setLangListShow(false);
      }
    };

    document.addEventListener("mousedown", handler);
  });
  return (
    <div
      className="mr-[15px] lg:mr-[28px] hidden md:block relative"
      ref={langRef}
    >
      <div
        className="cursor-pointer"
        onClick={() => setLangListShow(!langListShow)}
        ref={langRef}
      >
        {i18n.languages[0] === "en" && <img src={enFlag} alt="en" />}
        {i18n.languages[0] === "fr" && <img src={frFlag} alt="fr" />}
        {i18n.languages[0] === "esp" && <img src={espFlag} alt="esp" />}
      </div>
      {langListShow && (
        <div className="lang_list-wrapper after:translate-y-[300%] after:bg-accentWhite dark:after:hidden after:content-[''] after:absolute after:z-[2] after:w-[35px] after:h-[10px] before:content-[''] before:top-[124%] before:z-[1] before:absolute before:z-[1] before:w-[35px] before:h-[35px] before:translate-[135%]">
          <div className="lang_list z-[2] translate-y-[14%] bg-[#F8FFFF] left-[-72%] dark:bg-accentDark px-[26px] py-[28px] rounded-br-[12px] rounded-bl-[12px] flex flex-col items-center absolute">
            <button
              className="w-[34px] block mb-[36px]"
              onClick={langEn}
            >
              <img
                className={`w-[34px] transition-all h-[34px] ${
                  i18n.languages[0] === "en" ? "current-lang" : ""
                }`}
                src={enFlag}
                alt="en"
              />
            </button>
            <button
              className="w-[34px] block mb-[36px]"
              onClick={langFr}
            >
              <img
                className={`w-[34px] transition-all h-[34px] ${
                  i18n.languages[0] === "fr" ? "current-lang" : ""
                }`}
                src={frFlag}
                alt="fr"
              />
            </button>
            <button
              className="w-[34px] block"
              onClick={langSp}
            >
              <img
                className={`w-[34px] transition-all h-[34px] ${
                  i18n.languages[0] === "esp" ? "current-lang" : ""
                }`}
                src={espFlag}
                alt="esp"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
