import {spoilers} from '../models'

export const teamFaq: spoilers[] = [
  {
    title: "team-faq.item1.title",
    text: "team-faq.item1.text",
    id: 1,
    spoilerState: true
  },
  {
    title: "team-faq.item2.title",
    text: "team-faq.item2.text",
    id: 2,
    spoilerState: false
  },
  {
    title: "team-faq.item3.title",
    text: "team-faq.item3.text",
    id: 3,
    spoilerState: false
  },
  {
    title: "team-faq.item4.title",
    text: "team-faq.item4.text",
    id: 4,
    spoilerState: false
  },
  {
    title: "team-faq.item5.title",
    text: "team-faq.item5.text",
    id: 5,
    spoilerState: false
  },
]