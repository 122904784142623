import { Button } from "../button";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";

export function MailHero() {
  const { t } = useTranslation();
  return (
    <section className="mt-[150px] hero-section-mail relative">
      <div className="hidden md:block gradient-decor absolute max-w-[413px] top-[-40%] left-[-8%] rounded-[100%] bg-white max-h-[413px] w-full h-full"></div>
      <div className="container">
        <div className="grid items-center md:grid-cols-2 gap-[24px] md:gap-[35px]">
          <div className="relative z-[2]">
            <h1 className="relative z-[2] leading-[112%] md:mx-0 mx-auto text-center md:text-left max-w-[288px] md:max-w-[535px] text-[28px] lg:text-[35px] xl:text-[48px] font-black">
              {t("mail_page.hero-section.title")}
            </h1>
            <p className="font-bold text-center leading-[133%] md:text-left md:font-medium max-w-[288px] mx-auto md:mx-0 md:max-w-[449px] lg:text-[24px] mt-[20px] md:mt-[23px]">
              {t("mail_page.hero-section.text")}
            </p>
            <Button
              buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[87px] relative z-[3] transition-all md:block w-full text-center"
              buttonUrl="/GPM-Token"
              buttonText={t("mail_page.hero-section.button")}
            />
          </div>
          <div className="relative max-w-[271px] h-fit mx-auto md:max-w-none mx-auto">
            <div className="gradient-decor gradient-decor-low-blury absolute md:opacity-[35%] max-w-[219px] top-[-17%] right-[-30%] md:right-0 md:top-0 md:max-w-none md:max-h-100% w-full h-full z-[1]"></div>
            <dotlottie-player
              src="lottie/resseller-hero-dark.json"
              autoplay
              loop
              class="hidden dark:block relative z-[2]"
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
                zIndex: "2",
              }}
            />
            <dotlottie-player
              src="lottie/resseller-hero-white.json"
              autoplay
              loop
              class="dark:hidden relative z-[2]"
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
                zIndex: "2",
              }}
            />
          </div>
          <Button
            buttonClass="button relative z-[2] md:hidden mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full"
            buttonUrl="/GPM-Token"
            buttonText={t("mail_page.hero-section.button")}
          />
        </div>
      </div>
    </section>
  );
}
