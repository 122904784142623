import {features} from '../models'

export const featuresResellerData: features[] = [
  {
    image: "images/features1.png",
    title: "gpm_reseller.features.item1.title",
    text: "gpm_reseller.features.item1.text",    
    id: 1,
  },
  {
    image: "images/featured-digital.png",
    title: "gpm_reseller.features.item2.title",    
    text: "gpm_reseller.features.item2.text",    
    id: 2
  },
  {
    image: "images/reseller-features3.png",
    title: "gpm_reseller.features.item3.title",
    text: "gpm_reseller.features.item3.text",    
    id: 3
  },
]
