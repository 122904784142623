import { Button } from "../button";
import "@dotlottie/player-component";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";

export function ImageRightNoDecor(){
  useAos()
  const { t } = useTranslation();
  return(
    <section className="image-right relative overflow-hidden pt-[70px] pb-[70px] md:py-[126px]">
      <div className="container">
        <div className="md:grid relative z-[2] grid-cols-2 gap-[29px] items-center">
          <div data-aos="fade-up">
            <h2 className="font-extrabold text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{t("image-right2.title")}</h2>
            <dotlottie-player
              src="lottie/seller.lottie"
              autoplay
              loop
              class="block md:hidden mt-[23px]"
              style={{ height: "100%", width: "100%" }}
            />
            <p className="mt-[10px] lg:mt-[47px] text-center md:text-left text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">{t("image-right2.text")}</p>
            <Button buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[30px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full" buttonText={t("image-right2.button")} buttonUrl="/GPM-Reseller" />
          </div>
          <div data-aos="fade-left">
            <dotlottie-player
              src="lottie/seller.lottie"
              autoplay
              loop
              class="md:block hidden"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="gradient-decor absolute z-[1] md:hidden max-w-[250px] w-full h-full max-h-[250px] top-[37%] right-[-10%]"></div>
    </section>
  )
}