import {discontSlide} from '../models'

export const mailDiscontSlideData: discontSlide[] = [
  {
    image: "images/clock.png",
    title: "discont-slider.slide1.title",
    subtitle: "discont-slider.slide1.subtitle",
    discountPrice: "discont-slider.slide1.discountPrice",
    price: "discont-slider.slide1.price",
    percent: "-70%",
    id: 1
  },
  {
    image: "images/star.png",
    title: "discont-slider.slide3.title",
    subtitle: "discont-slider.slide3.subtitle",
    discountPrice: "discont-slider.slide3.discountPrice",
    price: "discont-slider.slide3.price",
    percent: "-45%",
    id: 2
  },
  {
    image: "images/package.png",
    title: "discont-slider.slide4.title",
    subtitle: "discont-slider.slide4.subtitle",
    discountPrice: "discont-slider.slide4.discountPrice",
    price: "discont-slider.slide4.price",
    percent: "-55%",
    id: 3
  }
]