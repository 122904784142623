import {locationSlideTumb} from '../models'

export const locationSlideTumbData: locationSlideTumb[] = [
  {
    image: "images/location1.png",
    title: "location-slider.slide-tumb1.country",
    id: 1
  },
  {
    image: "images/location2.png",
    title: "location-slider.slide-tumb2.country",
    id: 2
  },
  {
    image: "images/location3.png",
    title: "location-slider.slide-tumb3.country",
    id: 3
  },
  {
    image: "images/location4.png",
    title: "location-slider.slide-tumb4.country",
    id: 4
  }
]