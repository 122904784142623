import tree from "../../media/images/tree-man.gif";
import qrCode from "../../media/images/qr-code-token.png";

import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { useAos } from "../../utils/aos";
import { Parallax } from "react-parallax";

export function TokenTreeBackground() {
  const { t } = useTranslation();
  useAos();
  return (
    <section className="tree-background relative z-[2] overflow-hidden">
      <Parallax
        bgImage={require("../../media/images/tree-bg.png")}
        bgImageAlt="tree-bg"
        className="py-[30px] parallax md:py-[75px] mt-[65px] md:mt-[126px]"
        strength={-100}
      >
        <div className="container tree-background__container">
          <div className="bg-accentWhite/90 rounded-[6px] sm:rounded-[12px] grid grid-cols-2 items-center transition-all dark:bg-accentDark max-w-[340px] sm:max-w-[500px] md:max-w-[850px] lg:max-w-[1050px] md:pl-[40px] lg:pl-[73px] mx-auto p-[17px] md:px-[40px] md:py-[35px] ld:px-[73px]">
            <p
              data-aos="fade-right"
              className="font-extrabold text-[14px] md:text-[25px] lg:text-[32px] max-w-[350px] md:max-w-[431px] leading-[125%]"
            >
              {t("gpm_token.tree-background.text")}
            </p>
            <div data-aos="fade-left" className="relative">
              <img
                src={tree}
                alt="tree"
                className="z-[3] tree-man absolute bottom-[-22%] left-[-6%] sm:left-[29%] md:left-[-13%] lg:left-[1%] sm:bottom-[-11%] md:bottom-[-33%] xl:z-[3] max-w-[45px] md:max-w-[250px] lg:max-w-[280px]"
              />
              <div className="tree-background__widget ml-auto w-fit md:py-[32px] p-[14px] relative z-[2] md:px-[50px]">
                <a
                  href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block max-w-[80px] block dark:hidden sm:max-w-[100px] md:max-w-[202px] ml-auto"
                  title="View our Ecologi profile w-full"
                >
                  {" "}
                  <img
                    alt="We offset our carbon footprint via Ecologi"
                    src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?black=true&landscape=true"
                  />
                </a>
                <a
                  href="https://ecologi.com/gpm?r=61ffd6b9276354d4cb608c1f"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block max-w-[80px] dark:block hidden sm:max-w-[100px] md:max-w-[202px] ml-auto"
                  title="View our Ecologi profile w-full"
                >
                  {" "}
                  <img
                    alt="We offset our carbon footprint via Ecologi"
                    src="https://api.ecologi.com/badges/cpw/61ffd6b9276354d4cb608c1f?white=true&landscape=true"
                  />
                </a>
              </div>
              <img
                src={qrCode}
                alt="qr"
                className="absolute max-w-[150px] top-[-54%] right-[-47%] sm:max-w-[156px] md:max-w-[300px] sm:right-[-18%] z-[1] sm:top-[-37%]"
              />
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  );
}
