import { Button } from "../button";
import { Typewriter } from "react-simple-typewriter";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { heroSectionProps } from "../../models";

export function HeroSection(props: heroSectionProps) {
  const { t } = useTranslation();
  return (
    <section className="hero-section md:min-h-[550px] md:h-[78vh] h-full hero-section mt-[128px] md:mt-[167px] relative">
      <div className="hidden md:block gradient-decor absolute max-w-[413px] top-[-40%] left-[-8%] rounded-[100%] bg-white max-h-[413px] w-full h-full"></div>
      <div className="container__left h-full overflow-hidden">
        <div className="grid md:grid-cols-2">
          <div className="md:mt-[40px] px-[33px] md:px-0 text-center md:text-left">
            <h1 className="relative z-[2] mx-auto max-w-[600px] md:mx-0 leading-[111%] text-[28px] lg:text-[35px] xl:text-[56px] font-black max-w-[652px]">
              {props.title1} <br className="hidden md:block"></br>
              <b className="gradient-text inline-block md:min-w-[134px] lg:min-w-[170px] xl:min-w-[270px]">
                <Typewriter
                  words={[
                    `${t("hero-section.dynamic_title1")}`,
                    `${t("hero-section.dynamic_title2")}`,
                  ]}
                  loop={false}
                  typeSpeed={100}
                  deleteSpeed={100}
                  delaySpeed={1000}
                />
              </b>{" "}
              {props.title2}
              {props.title3}
            </h1>
            <p className="font-bold md:font-medium lg:text-[24px] mb-[45px] md:mb-0 mt-[20px]">
              {props.text}
            </p>
            <Button
              buttonClass="hidden max-w-[240px] text-[16px] md:text-[20px] button mt-[60px] relative z-[3] transition-all md:block w-full text-center"
              buttonUrl="#"
              buttonText={props.button}
            />
            <p className="text-[12px] mt-[10px] relative hidden md:block z-[5]">
              *To access GPM.Link you must have one of our NFTs
            </p>
          </div>
          <div className="hero-section__right mt-[30px] md:mt-0 relative md:left-[-40%]">
            <div className="gradient-decor z-[1] absolute max-w-[350px] max-h-[350px] md:max-w-[250px] md:max-h-[250px] top-[3%] md:top-[35%] xl:max-w-[413px] opacity-80 xl:top-[25%] left-[35%] md:left-[50%] rounded-[100%] bg-white xl:max-h-[413px] w-full h-full"></div>
            <div className="hero-section__image-wrapper">
              <dotlottie-player
                src="lottie/hero-section.lottie"
                autoplay
                loop
                class="hidden md:block max-w-[170%] relative z-[2] hero-section__image"
                style={{ height: "100%", width: "170%" }}
              />
            </div>
            <div className="block md:hidden min-h-[330px]">
              <dotlottie-player
                src="lottie/hero-section-mobile.lottie"
                autoplay
                loop
                class="hero-section__image-mobile relative left-[50%] translate-x-[-50%] scale-[1.3] mx-0 max-w-[500px] relative z-[2] w-full mx-auto min-h-[1px]"
                style={{ height: "100%", width: "170%" }}
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-[62px] md:hidden block button z-[3] left-[50%] translate-x-[-50%]">
          <Button
            buttonClass="whitespace-nowrap text-[16px]"
            buttonUrl="#"
            buttonText={props.button}
          />
        </div>
        <p className="text-[12px] absolute bottom-[20px] md:hidden block left-[50%] translate-x-[-50%] whitespace-nowrap text-center mt-[10px] z-[19]">
            *To access GPM.Link you must have one of our NFTs
          </p>
      </div>
    </section>
  );
}
