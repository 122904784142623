import { FaqSection } from "../components/sections/faq-section";
import ContactUs from "../components/sections/contact-form";
import { NewsForm } from "../components/sections/newsletter";
import { Features } from "../components/sections/features";
import { GpmTokenHero } from "../components/sections/gpm-token-hero";
//import { GpmLinkLefttImage } from "../components/sections/gpm-link-left-image";
import { DiscountSwiper } from "../components/sections/discont-swiper";
import { LocationSwiper } from "../components/sections/location-swiper";

import { tokenFeatures } from "../data/token-features";

import { useTranslation } from "react-i18next";
import "../utils/i18next";
import { RoadMap } from "../components/sections/road-map";
import { Calc } from "../components/sections/calc";
import { TokenTreeBackground } from "../components/sections/token-tree-background";
import { Referals } from "../components/sections/token-referals";
import { NftTypes } from "../components/sections/nft-types";
import { BuildProfile } from "../components/sections/profile-build-section";
import {} from "../data/spoilers";
import { tokenFaq } from "../data/tokenFaq";
import ScrollToTop from "../hooks/scrollToTop";
import { TokenHeader } from "../components/tokenHeader/tokenHeader";

export function GpmToken() {
  const { t } = useTranslation();
  return (
    <>
      <TokenHeader />
      <ScrollToTop />
      <main className="main gpm-token">
        <GpmTokenHero />
        <Features title={t("gpm_token.features.title")} data={tokenFeatures} />
        <Calc />
        <NftTypes />
        <TokenTreeBackground />
        {/* 
      <GpmLinkLefttImage title={t("gpm_link.image-left.title")} text={t("gpm_link.image-left.text")} button={t("gpm_link.image-left.button")} />
      */}
        <BuildProfile />
        <DiscountSwiper />
        <LocationSwiper
          title={t("location-slider.title")}
          text={t("location-slider.text")}
        />
        <Referals />
        <RoadMap />
        <FaqSection data={tokenFaq} />
        <ContactUs />
        <NewsForm />
      </main>
    </>
  );
}
