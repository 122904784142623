import { ButtonLink } from "../buttonLink";
import { gpmLinkImageProps } from "../../models"
import lottieAnim from '../../media/lottie/phone2.json'

import Lottie from 'react-lottie-player'
import { useAos } from "../../utils/aos";

export function GpmLinkRightImage(props :gpmLinkImageProps){
  useAos();
  return(
    <section className="image-right pt-[70px] pb-[70px] md:pb-[130px] md:pt-[152px]">
      <div className="container">
        <h2 data-aos="fade-up" className="block md:hidden md:max-w-[499px] relative max-w-[293px] text-center mx-auto z-[2] mb-[41px] font-extrabold mt-[30px] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{props.title}</h2>
        <div className="grid items-center md:grid-cols-2">
          <div data-aos="fade-left" className="grid-row-image relative z-[2] h-full flex flex-col justify-center">
            <h2 className="hidden md:block md:max-w-[499px] relative z-[2] font-extrabold mt-[30px] text-center md:text-left text-[24px] md:text-[30px] lg:text-[40px] leading-[125%]">{props.title}</h2>
            <p className="text-center mt-[30px] md:mt-[20px] md:text-left mx-auto md:mx-0 max-w-[499px] text-[14px] md:text-[16px] lg:text-[18px] leading-[167%]">{props.text}</p>
            <ButtonLink buttonClass="button mx-auto md:mx-0 transition-all block text-center text-[16px] md:text-[20px] mt-[30px] lg:mt-[40px] max-w-[190px] md:max-w-[247px] w-full" buttonText={props.button} buttonUrl="https://calendly.com/davidgpm/30min" />
          </div>
          <div data-aos="fade-right" className="relative">
            <div className="gradient-decor absolute top-[15%] left-[50%] z-[1] gradient-decor__plans w-full h-full max-w-[150px] md:max-w-[250px] max-h-[150px] md:max-h-[250px]"></div>
            <Lottie
              animationData={lottieAnim}
              play              
              loop
              style={{ height: "100%", width: "100%", position: "relative", zIndex: "2" }}
            />         
            <div className="gradient-decor absolute bottom-[-10%] left-[50%] z-[1] gradient-decor__plans w-full h-full max-w-[150px] md:max-w-[250px] max-h-[150px] md:max-h-[250px]"></div>
          </div>
        </div>
      </div>
    </section>
  )
}