import {discontSlide} from '../models'

export const discontSlideData: discontSlide[] = [
  {
    image: "images/clock.png",
    title: "discont-slider.slide1.title",
    subtitle: "discont-slider.slide1.subtitle",
    discountPrice: "discont-slider.slide1.discountPrice",
    price: "discont-slider.slide1.price",
    percent: "discont-slider.slide1.percent",
    thumb: "images/thumb-clock.png",
    id: 1
  },
  {
    image: "images/nike.png",
    title: "discont-slider.slide2.title",
    subtitle: "discont-slider.slide2.subtitle",
    discountPrice: "discont-slider.slide2.discountPrice",
    price: "discont-slider.slide2.price",
    percent: "discont-slider.slide2.percent",
    thumb: "images/nike-thumbnail.png",
    id: 2
  },
  {
    image: "images/star.png",
    title: "discont-slider.slide3.title",
    subtitle: "discont-slider.slide3.subtitle",
    discountPrice: "discont-slider.slide3.discountPrice",
    price: "discont-slider.slide3.price",
    percent: "discont-slider.slide3.percent",
    thumb: "images/thumb-star.png",
    id: 3
  },
  {
    image: "images/package.png",
    title: "discont-slider.slide4.title",
    subtitle: "discont-slider.slide4.subtitle",
    discountPrice: "discont-slider.slide4.discountPrice",
    price: "discont-slider.slide4.price",
    percent: "discont-slider.slide4.percent",
    thumb: "images/thumb-package.png",
    id: 4
  }
]