import {earnPoints} from '../models'

export const earnPointsData: earnPoints[] = [
  {
    image: "images/earn-points1.svg",
    text: "earn-points.item1",    
    id: 1,
  },
  {
    image: "images/earn-points2.svg", 
    text: "earn-points.item2",    
    id: 2
  },
  {
    image: "images/icon1.svg",
    text: "earn-points.item3",    
    id: 3
  },
  {
    image: "images/earn-points4.svg",
    text: "earn-points.item4",    
    id: 4
  }
]
