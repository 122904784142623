import {spoilers} from '../models'

export const faqSpoilers: spoilers[] = [
  {
    title: "faq.spoiler1.title",
    text: "faq.spoiler1.text",
    id: 1,
    spoilerState: true
  },
  {
    title: "faq.spoiler2.title",
    text: "faq.spoiler2.text",
    id: 2,
    spoilerState: false
  },
  {
    title: "faq.spoiler3.title",
    text: "faq.spoiler3.text",
    id: 3,
    spoilerState: false
  },
  {
    title: "faq.spoiler4.title",
    text: "faq.spoiler4.text",
    id: 4,
    spoilerState: false
  },
]