import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import "../../utils/i18next";
import { Pagination, EffectFade, Autoplay } from "swiper";
import { useAos } from "../../utils/aos";
import { Button } from "../button";
import { benefitsData } from "../../data/travel-benefits";

export function GpmTravelBenefits() {
  const { t } = useTranslation();
  useAos();

  const gallerySwiperParams: SwiperProps = {
    spaceBetween: 10,
    speed: 1500,
    autoHeight: true,
    loop: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination-travel",
      clickable: true,
    },
  };
  return (
    <section className="mt-[30px] relative mb-[50px]">
      <div className="container">
        <div className="gradient-decor md:block hidden w-full h-full max-h-[200px] max-w-[200px] right-[10%] top-[-3%] absolute"></div>
        <Swiper
          className="benefits-slider relative z-[3]"
          modules={[Pagination, EffectFade, Autoplay]}
          effect="fade"
          {...gallerySwiperParams}
        >
          {benefitsData.map((item) => (
            <SwiperSlide key={item.id}>
              <div
                className={`bg-cover min-h-[288px] md:min-h-[237px] benefits-slider__content benefits-slider__content${item.id} before:left-0 before:z-[1] relative before:w-full before:h-full before:content-[''] before:rounded-[16px] md:before:rounded-[20px] before:absolute flex items-center rounded-[16px] md:rounded-[20px] px-[28px] py-[50px] md:p-[50px] bg-center bg-no-repeat`}
                style={{ backgroundImage: `url(${item.image})` }}
              >
                <h2 className={`text-center benefits-slider__title${item.id} relative text-white z-[4] text-[24px] md:text-[36px] leading-[128%] max-w-[233px] md:max-w-[600px] relative z-[2] font-extrabold mx-auto`}>
                  {t(`${item.text1}`)}
                  <b> {t(`${item.text2}`)} </b>
                  {t(`${item.text3}`)}
                </h2>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination-travel flex justify-center gap-[8px] relative z-[4] mt-[19px] md:mt-[29px]"></div>
        <Button
          buttonClass="relative z-[4] button mx-auto transition-all block text-center text-[16px] md:text-[20px] mt-[48px] lg:mt-[58px] max-w-[190px] md:max-w-[247px] w-full"
          buttonText={t("benefits.button")}
          buttonUrl="/GPM-Link"
        />
      </div>
    </section>
  );
}
