import logo from "../../media/images/desktop_logo.webp";
import mobileLogo from "../../media/images/logo.png";
import { ButtonLink } from "../buttonLink";
import { BurgerMenu } from "../header/burger-menu";
import { LangSwitcher } from "../header/language-switcher";
import { SubMenu } from "../header/sub-menu";
import { ThemeSwitcher } from "../header/theme-switcher";

import { useTranslation  } from "react-i18next";
import "../../utils/i18next";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";


export function TokenHeader() {
  const [subMenu, setSubMenu] = useState(false);
  const { t } = useTranslation();

  let menuRef = useRef<any>();

  useEffect(() =>{
    const node = menuRef.current;
    let handler = (e: any)=>{
      if(!node?.contains(e.target)){
        setSubMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);
  })
  return (
    <header className="header fixed top-0 left-0 w-full z-20 bg-whiteGradient dark:bg-darkGradient">
      <div className="flex items center flex-col container h-[70px] md:h-[92px]">
        <div className="flex items-center h-full justify-between">
          <div className="hidden md:flex items-center">
            <Link to="/" className="mr-[20px] lg:mr-[61px]">
              <img width="45px" height="45px" src={logo} alt="logo" />
            </Link>
            <Link to="/" className="transition-all mr-5 lg:mr-14 hover:text-purple">{t("header.home_link")}</Link>
            <div className="mr-5 lg:mr-14 relative" ref={menuRef}>
              <p onClick={() => setSubMenu(!subMenu)} className={`transition-all cursor-pointer select-none	hover:text-purple ${subMenu && 'text-purple'}`}>{t("header.products_link")}</p>
              {subMenu && <SubMenu click={() => setSubMenu(false)} />}
            </div>
            <a href="/" className="transition-all hover:text-purple">Whitepaper</a>
          </div>
          <BurgerMenu />
          <Link to="/" className="md:hidden relative z-[21]">
            <img src={mobileLogo} alt="logo" className="max-w-[120px]" />
          </Link>
          <div className="flex items-center">
            <LangSwitcher />
            <ThemeSwitcher />
            <ButtonLink
              buttonClass="hidden md:block button button-bordered mr-[10px] lg:mr-[18px] transition-all"
              buttonUrl="/"
              buttonText="Access App"
            />
            { /* 
            <Button
              buttonClass="hidden header-button md:block button transition-all"
              buttonUrl="#"
              buttonText={t("header.button")}
            />
            */}
          </div>
        </div>
        <div className="gradient-border hidden md:block" />
      </div>
    </header>
  );
}
